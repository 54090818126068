import { toast } from 'react-toastify';
import { createTopupRequest, getTopupRequest } from '../../api/TopupRequest';
import { EMPTY_LIST, GET_TOPUP, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getTopupAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getTopupRequest(values);

    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    // console.log('response get top up action', data);
    dispatch({ type: GET_TOPUP, payload: data?.content?.data?.all });
    return 0;
  } catch (err) {
    console.log('error here', err);
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createTopupAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await createTopupRequest(reqObj);
    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    toast.success('Created Sucessfully!');
    dispatch(getTopupAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
