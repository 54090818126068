import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import logo from '../assets/lamata-logo.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <img alt="logo" src={logo} style={{ width: 50, height: 50, ...sx }} />;

  // return <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />;
}
