import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updatePricePlanAction } from 'src/store/actions/PricePlanActions';

function UpdatePlan({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue
  } = useFormik({
    initialValues: {
      onViewPrice: '',
      onClickPrice: '',
      onConversionPrice: ''
    },
    validationSchema: Yup.object().shape({
      onViewPrice: Yup.string().required('Required'),
      onClickPrice: Yup.string().required('Required'),
      onConversionPrice: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const response = await dispatch(updatePricePlanAction(values, rowData.id));
      setLoading(false);
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      } else handleClose();
    }
  });

  useEffect(() => {
    if (rowData) {
      setFieldValue('onViewPrice', rowData.onViewPrice);
      setFieldValue('onClickPrice', rowData.onClickPrice);
      setFieldValue('onConversionPrice', rowData.onConversionPrice);
    }
  }, [rowData]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Price Plan</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              margin="normal"
              id="onViewPrice"
              label="Price on Ad View"
              type="number"
              fullWidth
              value={values.onViewPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.onViewPrice && errors.onViewPrice)}
              helperText={touched.onViewPrice && errors.onViewPrice}
            />
            <TextField
              margin="normal"
              id="onClickPrice"
              label="Price on Ad Click"
              type="number"
              fullWidth
              value={values.onClickPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.onClickPrice && errors.onClickPrice)}
              helperText={touched.onClickPrice && errors.onClickPrice}
            />
            <TextField
              margin="normal"
              id="onConversionPrice"
              label="Price on Ad Conversion"
              type="number"
              fullWidth
              value={values.onConversionPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.onConversionPrice && errors.onConversionPrice)}
              helperText={touched.onConversionPrice && errors.onConversionPrice}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Update Price Plan
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdatePlan;
