import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton, LocalizationProvider } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

function FilterDeviceViews({ handleFilter, loading, isDevice }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [fromDateState, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DDThh:mm'));
  const [toDateState, setToDate] = useState(moment().format('YYYY-MM-DDThh:mm'));

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        busID: '',
        fromDate: moment().subtract(1, 'd').format('YYYY-MM-DDThh:mm') || '',
        toDate: moment().format('YYYY-MM-DDThh:mm') || '',
        clicks: '',
        views: '',
      },
      onSubmit: (values) => {
        const fdate = moment(fromDateState).unix();
        const tdate = moment(toDateState).unix();
        handleFilter({
          ...values,
          fromDate: fdate,
          toDate: tdate,
        });
      }
    });

  const handleClear = (key) => {
    if (key === 'fromDate') {
      setFieldValue('fromDate', '');
      setFromDate('');
    }
    if (key === 'toDate') {
      setFieldValue('toDate', '');
      setToDate('');
    }
   
  };

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <TextField
              margin="normal"
              id="fromDate"
              label="From Date"
              type="datetime-local"
              fullWidth
              value={values.fromDate}
              defaultValue={fromDateState}
              onChange={(e) => {
                setFromDate(e.target.value);
                setFieldValue('fromDate', e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.fromDate && errors.fromDate)}
              helperText={touched.fromDate && errors.fromDate}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear('fromDate')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="datetime-local"
              fullWidth
              defaultValue={toDateState}
              value={values.toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                setFieldValue('toDate', e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.toDate && errors.toDate)}
              helperText={touched.toDate && errors.toDate}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear('toDate')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid sm={3}>
            <LoadingButton
              style={{ marginTop: 40, marginLeft: 20 }}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>

      </form>
    </div>
  );
}

export default FilterDeviceViews;