import { getDayBefore, getTodayDate } from 'src/utils/utilities';
import axios from './index';

export const getTopupRequest = (values) => {
  return axios.get(
    `/v1/admins/get/topups/-/${values?.vendorID || '-'}/${values?.walletID || '-'}/${
      values?.reference || '-'
    }/${values?.status || '-'}/${values?.from || getDayBefore()}/${
      values?.to || getTodayDate()
    }/-/-`
  );
};

export const createTopupRequest = (reqObj) => axios.post('/v1/admins/create/topup', reqObj);
