import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Tooltip
} from '@material-ui/core';
import { Label, Close } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import TOPUPLIST from '../../_mocks_/topup.json';
import NewPrice from './components/NewPrice';
import { getTopupAction } from '../../store/actions/TopupActions';
import { fCurrency } from '../../utils/formatNumber';
import { fDateTime, fDateTimeSuffix } from '../../utils/formatTime';
import { disablePriceAction, getPriceAction } from '../../store/actions/PriceActions';

import FilterPrice from './components/FilterPrice';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullname', label: 'Vendor Fullname', alignRight: false },
  { id: 'price', label: 'Set Price per Ad', alignRight: false },
  { id: 'eventType', label: 'Event', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.fullname?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

function PriceModule() {
  const dispatch = useDispatch();
  const { isLoading, PRICELIST } = useSelector((state) => state.prices);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('fullname');
  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openNewPrice, setOpenNewPrice] = useState(false);

  const [filterLoader, setFilterLoader] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - PRICELIST?.length) : 0;

  const filteredUsers = applySortFilter(PRICELIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const toggleModal = () => {
    setOpenNewPrice(!openNewPrice);
  };

  useEffect(() => {
    dispatch(getPriceAction());
  }, []);

  const handleToggleStatus = async (id) => {
    const action = window.confirm('Are you sure you want to DISABLE this Price?');
    if (action) {
      const response = await dispatch(disablePriceAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      }
    }
  };

  const _filterPrice = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getPriceAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  return (
    <Page title="Price Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Price Mgmt
          </Typography>
          <Button variant="contained" onClick={toggleModal} startIcon={<Icon icon={plusFill} />}>
            New Price
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterPrice handleFilter={_filterPrice} loading={filterLoader} />
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? <LinearProgress /> : null}
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={PRICELIST?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //   onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { id, price, active, dateCreated, vendor, eventType } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          //   selected={isItemSelected}
                          //   aria-checked={isItemSelected}
                        >
                          <TableCell>
                            <Label />
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{vendor?.fullname || 'All vendors'}</TableCell>
                          <TableCell align="left"> {fCurrency(price)} </TableCell>
                          <TableCell align="left"> {eventType} </TableCell>
                          <TableCell align="left">
                            <Stack
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Chip
                                label={active == '1' ? 'Active' : 'Inactive'}
                                color={active == '1' ? 'primary' : 'default'}
                                clickable
                              />

                              {active == '1' && (
                                <Tooltip title="Deactivate">
                                  <Close
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleToggleStatus(id)}
                                  />
                                </Tooltip>
                              )}
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{fDateTime(dateCreated)}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={PRICELIST?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {/* Modals */}
        <NewPrice open={openNewPrice} handleClose={toggleModal} />
      </Container>
    </Page>
  );
}

export default PriceModule;
