import { getDashboardRequest } from '../../api/DashboardRequest';
import { GET_DASHBOARD, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getDashboardAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getDashboardRequest();

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    dispatch({ type: GET_DASHBOARD, payload: data.content.data });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
