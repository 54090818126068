import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Grid,
  Autocomplete
} from '@material-ui/core';
import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { getAdAction } from 'src/store/actions/AdActions';
import { sortArrayBy } from 'src/utils/utilities';
import { getRoutesAction } from 'src/store/actions/RoutesActions';
import {
  getCitiesAction,
  getCountriesAction,
  getStatesAction
} from 'src/store/actions/WorldActions';
import moment from 'moment';

function FilterAds({ handleFilter, loading }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const dispatch = useDispatch();
  const [options, setOptions] = useState({ country: [], state: [], city: [] });
  const { VENDORS_LIST } = useSelector((state) => state.vendors);
  const { ROUTES_LIST } = useSelector((state) => state.routes);
  const { countries, states, cities } = useSelector((state) => state.world);
  //   const { isLoading } = useSelector((state) => state.Ads);

  useEffect(() => {
    dispatch(getVendorsAction());
    dispatch(getRoutesAction());
    dispatch(getCountriesAction());
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      const countryOptions = countries.map((item) => ({ label: item.name, id: item.id }));
      const stateOptions = states.map((item) => ({ label: item.name, id: item.id }));
      const cityOptions = cities.map((item) => ({ label: item.name, id: item.id }));

      countryOptions.sort((a, b) => a.label.localeCompare(b.label));
      stateOptions.sort((a, b) => a.label.localeCompare(b.label));
      cityOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions((prev) => ({
        country: [...countryOptions],
        state: [...stateOptions],
        city: [...cityOptions]
      }));
    }
  }, [countries, states, cities]);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        vendorID: '',
        routeID: '',
        countryID: null,
        countryLabel: '',
        stateID: null,
        stateLabel: '',
        cityID: null,
        cityLabel: '',
        limit: '',
        accessStatus: '',
        dateFrom: '',
        dateTo: '',
        adType: ''
      },
      validationSchema: Yup.object().shape({
        vendorID: Yup.string(),
        countryID: Yup.string().nullable(),
        stateID: Yup.string().nullable(),
        cityID: Yup.string().nullable()
      }),
      onSubmit: handleFilter
    });

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={5} justifyContent="space-between"> */}
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.vendorID && errors.vendorID)}
              helperText={touched.vendorID && errors.vendorID}
              fullWidth
              select
              margin="normal"
              name="vendorID"
              label="Vendor"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.vendorID}
            >
              <MenuItem value="">Please select Vendor</MenuItem>
              {Boolean(VENDORS_LIST) &&
                sortArrayBy(VENDORS_LIST, 'fullname').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.fullname}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.routeID && errors.routeID)}
              helperText={touched.routeID && errors.routeID}
              fullWidth
              select
              margin="normal"
              name="routeID"
              label="Routes"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.routeID}
            >
              <MenuItem value="">Please select Route</MenuItem>
              {Boolean(ROUTES_LIST) &&
                sortArrayBy(ROUTES_LIST, 'name').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item sm={3}>
            <Autocomplete
              disablePortal
              id="countryID"
              options={options.country}
              name="countryID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('countryID', newValue?.id || null);
                setFieldValue('countryLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getStatesAction({ countryID: newValue.id }));
                }
              }}
              fullWidth
              value={values.countryLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="Country" />}
            />
          </Grid>

          <Grid item sm={3}>
            <Autocomplete
              disablePortal
              id="stateID"
              options={options.state}
              name="stateID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('stateID', newValue?.id || null);
                setFieldValue('stateLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getCitiesAction({ stateID: newValue.id }));
                }
              }}
              fullWidth
              value={values.stateLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="State" />}
            />
          </Grid>

          <Grid item sm={3}>
            <Autocomplete
              disablePortal
              id="cityID"
              options={options.city}
              name="cityID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('cityID', newValue?.id || null);
                setFieldValue('cityLabel', newValue?.label);
              }}
              fullWidth
              value={values.cityLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="City" />}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="count"
              label="Fetch Count"
              type="text"
              fullWidth
              value={values.limit}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.limit && errors.limit)}
              helperText={touched.limit && errors.limit}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="dateFrom"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => {
                setFieldValue('dateFrom', moment(e.target.value).unix());
                setFromDate(e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.dateFrom && errors.dateFrom)}
              helperText={touched.dateFrom && errors.dateFrom}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="dateTo"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              //   value={toDate}
              onChange={(e) => {
                setFieldValue('dateTo', moment(e.target.value).unix());
                setToDate(e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.dateTo && errors.dateTo)}
              helperText={touched.dateTo && errors.dateTo}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.accessStatus && errors.accessStatus)}
              helperText={touched.accessStatus && errors.accessStatus}
              fullWidth
              select
              margin="normal"
              name="accessStatus"
              label="Status"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.accessStatus}
            >
              <MenuItem value="">Please select </MenuItem>
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Inactive</MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.adType && errors.adType)}
              helperText={touched.adType && errors.adType}
              fullWidth
              select
              margin="normal"
              name="adType"
              label="Ad Type"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.adType}
            >
              <MenuItem value="">Please select </MenuItem>
              <MenuItem value="media">Media</MenuItem>
              <MenuItem value="app">App</MenuItem>
              <MenuItem value="form">Form</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <LoadingButton
              style={{ marginTop: '20px' }}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>

        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterAds;
