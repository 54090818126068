import { removeDupsFromArray } from '../../utils/utilities';
import { EMPTY_LIST, GET_ADS, GET_ROUTES, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  ADSLIST: [],
  ROUTES: [],
  isLoading: false
};

export const AdsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_ADS: {
      return {
        ...state,
        ADSLIST: removeDupsFromArray([...payload]),
        isLoading: false
      };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    case EMPTY_LIST: {
      return { ...state, isLoading: false, ADSLIST: [] };
    }
    case GET_ROUTES: {
      return { ...state, ROUTES: payload, isLoading: false };
    }
    default:
      return state;
  }
};
