import { GET_VENDORS, LOAD_REQUEST } from '../types';

const initialState = {
  VENDORS_LIST: [],
  isLoading: false
};

export const VendorsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_VENDORS: {
      return { ...state, VENDORS_LIST: payload, isLoading: false };
    }
    default:
      return state;
  }
};
