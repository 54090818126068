import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormControl, InputLabel, Select, MenuItem, Stack, Grid } from '@material-ui/core';
import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { getAdAction } from 'src/store/actions/AdActions';
import { sortArrayBy } from 'src/utils/utilities';
import { getWalletAction } from 'src/store/actions/WalletActions';
import { getRoutesAction } from 'src/store/actions/RoutesActions';

function TransactionsFilter({ handleFilter, loading }) {
  const dispatch = useDispatch();
  const { VENDORS_LIST } = useSelector((state) => state.vendors);
  const { TRANSACTIONS_LIST } = useSelector((state) => state.transactions);
  const { ROUTES_LIST } = useSelector((state) => state.routes);
  const { ADSLIST } = useSelector((state) => state.Ads);

  const [isLoading, setIsLoading] = useState(false);
  // const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
  const [fromDate, setFromDate] = useState(moment().subtract(5, 'm').format('YYYY-MM-DD HH:mm'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD HH:mm'));

  useEffect(() => {
    dispatch(getVendorsAction());
    dispatch(getAdAction());
    dispatch(getRoutesAction());
  }, []);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      vendorID: '',
      adID: '',
      routeID: '',
      from: '',
      to: ''
    },
    validationSchema: Yup.object().shape({
      //   vendorID: Yup.string().required('Vendor is required')
    }),
    onSubmit: (values) => {
      const fdate = moment(fromDate).unix();
      const tdate = moment(toDate).unix();
      handleFilter({ ...values, to: tdate, from: fdate });
    }
  });

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.vendorID && errors.vendorID)}
              helperText={touched.vendorID && errors.vendorID}
              fullWidth
              select
              margin="normal"
              name="vendorID"
              label="Vendor"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.vendorID}
            >
              <MenuItem value="">Please select Vendor</MenuItem>
              {Boolean(VENDORS_LIST) &&
                sortArrayBy(VENDORS_LIST, 'fullname').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.fullname}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.routeID && errors.routeID)}
              helperText={touched.routeID && errors.routeID}
              fullWidth
              select
              margin="normal"
              name="routeID"
              label="Routes"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.routeID}
            >
              <MenuItem value="">Please select Route</MenuItem>
              {Boolean(ROUTES_LIST) &&
                sortArrayBy(ROUTES_LIST, 'name').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="fromDate"
              label="From Date"
              type="datetime-local"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onBlur={handleBlur}
              error={Boolean(touched.from && errors.from)}
              helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="datetime-local"
              fullWidth
              defaultValue={toDate}
              //   value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onBlur={handleBlur}
              error={Boolean(touched.to && errors.to)}
              helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default TransactionsFilter;
