import { toast } from 'react-toastify';
import {
  createPriceRequest,
  disablePriceRequest,
  getConstantsRequest,
  getPriceRequest
} from '../../api/PriceRequest';

import { EMPTY_LIST, GET_CONSTANTS, GET_PRICE, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getPriceAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getPriceRequest(values);
    // checkIfResponseOk(data, dispatch);
    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_PRICE, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const getConstantsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getConstantsRequest();
    // checkIfResponseOk(data, dispatch);
    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_CONSTANTS, payload: data.content.data.priceTypes });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createPriceAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    if (obj.vendorID === 'null') {
      obj.vendorID = null;
    }
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await createPriceRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!');
    dispatch(getPriceAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const disablePriceAction = (priceID) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await disablePriceRequest(priceID);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Disabled Sucessfully!');
    dispatch(getPriceAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
