import moment from 'moment';
import { array } from 'prop-types';

export const sortArrayBy = (arr, name) => {
  if (name) return arr.sort((a, b) => a[name]?.localeCompare(b[name]));
  return arr.sort();
};

// export const sortNestedArrayBy = (arr, parent, child) => {
//   return arr.sort((a, b) => Object.values(arr).forEach(item)
//   );
// };

export const getDayBefore = () => moment().subtract(1, 'd').unix();
export const getMinsBefore = (mins) => moment().subtract(mins, 'm').unix();

export const getTodayDate = () => moment().unix();

export const getTableHeader = (tablehead) =>
  tablehead.map((item) => ({ key: item.id, label: item.label }));

export const getTableData = (data = [], table = []) => {
  const result = data.map((item, index) => {
    const fields = {};
    for (let i = 0; i < table.length; i += 1) {
      const curritem = table[i];
      // { [table[i].id]: table[i]?.render ? table[i].render(i, item) : item[table[i].id]  }
      if (curritem.id !== 'action') {
        if (curritem?.render) {
          // console.log(curritem, curritem?.render);
          // handles the condition if the item returns a react element, eg button, paragraph
          if (typeof curritem.render(i, item) === 'object')
            fields[curritem.id] = curritem.render(i, item)?.props?.children?.join(' ');
          else fields[curritem.id] = curritem.render(i, item);

          if (curritem.id === 'id') fields[curritem.id] = index + 1;
        } else {
          fields[curritem.id] = item[curritem.id];
        }
      }
    }
    return fields;
  });
  return result;
};

export const removeDupsFromArray = (arr = []) => {
  if (arr.length === 0) return [];
  return arr.filter(
    (item, index, array) => array.findIndex((dupItem) => dupItem.id === item.id) === index
  );
};
