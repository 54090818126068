import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdminLogsAction,
  getLogsAction,
  getVendorLogsAction
} from 'src/store/actions/LogsActions';
import CustomTable from './CustomTable';
import { toast } from 'react-toastify';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullname', label: 'Fullname', alignRight: false },
  { id: 'activityLogDesc', label: 'Action Description', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false }
  // { id: '', label: 'Actions' }
];

function LogTabs() {
  const [value, setValue] = React.useState(0);
  const [filterLoader, setFilterLoader] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, ADMIN_LOG_LIST, LOGLIST, VENDORS_LOG_LIST } = useSelector(
    (state) => state.logs
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getAdminLogsAction());
    dispatch(getLogsAction());
    dispatch(getVendorLogsAction());
  }, []);

  const _filterSelfLog = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getLogsAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined
      });
    }
    setFilterLoader(false);
  };

  const _filterAdminLog = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getAdminLogsAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined
      });
    }
    setFilterLoader(false);
  };

  const _filterVendorLog = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getVendorLogsAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined
      });
    }
    setFilterLoader(false);
  };
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="My Logs" />
        <Tab label="Admin Logs" />
        <Tab label="Vendor Logs" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <CustomTable
          data={LOGLIST}
          header={TABLE_HEAD}
          isLoading={isLoading}
          _filterLog={_filterSelfLog}
          loading={filterLoader}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomTable
          data={ADMIN_LOG_LIST}
          header={TABLE_HEAD}
          isLoading={isLoading}
          _page="admin"
          _filterLog={_filterAdminLog}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomTable
          data={VENDORS_LOG_LIST}
          header={TABLE_HEAD}
          isLoading={isLoading}
          _page="vendor"
          _filterLog={_filterVendorLog}
        />
      </TabPanel>
    </Box>
  );
}

export default LogTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
