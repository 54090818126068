import { EMPTY_LIST, GET_CONSTANTS, GET_PRICE, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  PRICELIST: [],
  isLoading: false,
  constants: []
};

export const PriceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_PRICE: {
      return { ...state, PRICELIST: payload, isLoading: false };
    }

    case EMPTY_LIST: {
      return { ...state, isLoading: false, PRICELIST: [] };
    }

    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    case GET_CONSTANTS: {
      return { ...state, constants: payload };
    }
    default:
      return state;
  }
};
