import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Tooltip
} from '@material-ui/core';
import { Label, Close } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import { fCurrency } from '../../utils/formatNumber';
import { fDateTime, fDateTimeSuffix } from '../../utils/formatTime';
import { disablePriceAction, getPriceAction } from '../../store/actions/PriceActions';
import { disablePricePlanAction, getPricePlanAction } from 'src/store/actions/PricePlanActions';
import CreatePlan from './components/CreatePlan';
import UpdatePlan from './components/UpdatePlan';
import { Edit } from '@material-ui/icons';
import Table from '../../components/table';

function PricePlan() {
  const dispatch = useDispatch();
  const { isLoading, pricePlan } = useSelector((state) => state.pricePlan);

  const [openNewPrice, setOpenNewPrice] = useState(false);
  const [openUpdatePlan, setOpenUpdatePlan] = useState(false);
  const [rowData, setRowData] = useState(null);

  const toggleModal = () => {
    setOpenNewPrice(!openNewPrice);
  };

  useEffect(() => {
    dispatch(getPricePlanAction());
  }, []);

  const handleToggleStatus = async (id) => {
    const action = window.confirm('Are you sure you want to DISABLE this Price Plan?');
    if (action) {
      const response = await dispatch(disablePricePlanAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      }
    }
  };

  // const _filterPrice = async (values) => {
  //   setFilterLoader(true);
  //   const response = await dispatch(getPriceAction(values));
  //   if (response?.error === 1) {
  //     toast.info(`${response.message}`);
  //   }
  //   setFilterLoader(false);
  // };

  const toggleUpdatePlan = (row) => {
    setOpenUpdatePlan(!openUpdatePlan);
    setRowData(row);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'name',
      label: 'Ad Name',
      align: 'left',
      render: (index, rowData) => rowData?.name
    },
    {
      id: 'onViewPrice',
      label: 'Ad-View Price',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData.onViewPrice)
    },
    {
      id: 'onClickPrice',
      label: 'Ad-Click Price',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData.onClickPrice)
    },
    {
      id: 'onConversionPrice',
      label: 'Ad-Conversion Price',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData.onConversionPrice)
    },
    {
      id: 'views',
      label: 'Status',
      align: 'left',
      render: (index, rowData) => (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Chip
            label={rowData.accessStatus == '1' ? 'Active' : 'Inactive'}
            color={rowData.accessStatus == '1' ? 'primary' : 'default'}
            clickable
          />
          {rowData.accessStatus == '1' && (
            <Tooltip title="Disable">
              <Close style={{ cursor: 'pointer' }} onClick={() => handleToggleStatus(rowData.id)} />
            </Tooltip>
          )}
        </Stack>
      )
    },
    {
      id: 'views',
      label: 'Vendor Fullname',
      align: 'left',
      render: (index, rowData) => rowData?.vendor?.fullname || 'All'
    },
    {
      id: 'views',
      label: 'Date Created',
      align: 'left',
      render: (index, rowData) => fDateTime(rowData.dateCreated)
    },
    {
      id: 'action',
      label: 'Action',
      align: 'left',
      render: (index, rowData) => <Edit onClick={() => toggleUpdatePlan(rowData)} />
    }
  ];

  return (
    <Page title="Price Plan Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Price Plan
          </Typography>
          <Button variant="contained" onClick={toggleModal} startIcon={<Icon icon={plusFill} />}>
            New Price Plan
          </Button>
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterPrice handleFilter={_filterPrice} loading={filterLoader} />
        </Stack> */}

        <Table
          tableHeader={TABLE_HEAD}
          data={pricePlan}
          isLoading={isLoading}
          filterField="name"
          tableTitle="Price Plan"
          // getMoreData={handleGetMoreData}
        />
        {/* Modals */}
        <CreatePlan open={openNewPrice} handleClose={toggleModal} />
        <UpdatePlan open={openUpdatePlan} handleClose={toggleUpdatePlan} rowData={rowData} />
      </Container>
    </Page>
  );
}

export default PricePlan;
