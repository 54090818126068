import { ToastContainer } from 'react-toastify';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------
// console.log = function () {};
export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
      <ToastContainer />
    </ThemeConfig>
  );
}
