import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormControl, InputLabel, Select, MenuItem, Stack, Grid } from '@material-ui/core';
import { sortArrayBy } from 'src/utils/utilities';
import { getAdAction } from 'src/store/actions/AdActions';
import { getVendorsAction } from 'src/store/actions/VendorsActions';

function FilterConversions({ handleFilter, loading, page }) {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.Ads.ADSLIST);
  const vendors = useSelector((state) => state.vendors.VENDORS_LIST);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      from: '',
      to: '',
      adID: '',
      vendorID: ''
    },
    onSubmit: (values) => {
      const fdate = moment(fromDate).unix();
      const tdate = moment(toDate).unix();
      handleFilter({ to: tdate, from: fdate, adID: values.adID, vendorID: values.vendorID });
    }
  });

  useEffect(() => {
    dispatch(getAdAction());
    dispatch(getVendorsAction());
  }, []);

  return (
    <div style={{ width: '100%', marginBottom: 20 }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.vendorID && errors.vendorID)}
              helperText={touched.vendorID && errors.vendorID}
              fullWidth
              select
              margin="normal"
              name="vendorID"
              label="Vendors"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.vendorID}
            >
              <MenuItem value="">Please select Vendor</MenuItem>
              {Boolean(vendors) &&
                sortArrayBy(vendors, 'fullname').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.fullname}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.adID && errors.adID)}
              helperText={touched.adID && errors.adID}
              fullWidth
              select
              margin="normal"
              name="adID"
              label="Ads"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.adID}
            >
              <MenuItem value="">Please select Ad</MenuItem>
              {Boolean(ads) &&
                sortArrayBy(ads, 'name').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name || `Ad ${item.id}`}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              //   onBlur={handleBlur}
              //   error={Boolean(touched.from && errors.from)}
              //   helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              onChange={(e) => setToDate(e.target.value)}
              //   onBlur={handleBlur}
              //   error={Boolean(touched.to && errors.to)}
              //   helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterConversions;
