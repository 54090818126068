import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@material-ui/lab';
import { updateVendorAction } from '../../../store/actions/VendorsActions';

function UpdateVendor({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.vendors);

  useEffect(() => {
    if (rowData) {
      const { fullname, username, phone, email, address, profilePicture } = rowData;
      setFieldValue('fullname', fullname);
      setFieldValue('username', username);
      setFieldValue('phone', phone);
      setFieldValue('email', email);
      setFieldValue('address', address);
      setFieldValue('profilePicture', profilePicture);
    }
  }, [rowData]);

  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleBlur,
    values,
    setFieldValue
  } = useFormik({
    initialValues: {
      fullname: ``,
      username: ``,
      phone: ``,
      email: ``,
      address: ``
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required('Fullname is required'),
      username: Yup.string().required('Username is required'),
      phone: Yup.string().required('Phone is required'),
      email: Yup.string().required('Email is required'),
      address: Yup.string().required('Address is required'),
      profilePicture: Yup.string()
    }),
    onSubmit: async (values) => {
      console.log('submit values', values);
      const response = await dispatch(updateVendorAction(rowData.id, { ...values }));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else handleClose();
    }
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Vendor Information</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              id="fullname"
              name="fullname"
              label="Fullname"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullname}
              error={Boolean(errors.fullname && touched.fullname)}
              fullWidth
            />
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              error={Boolean(errors.username && touched.username)}
              helperText={touched.username && errors.username}
            />
            <TextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={Boolean(errors.phone && touched.phone)}
              helperText={touched.phone && errors.phone}
            />
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={Boolean(errors.email && touched.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              id="address"
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={Boolean(errors.address && touched.address)}
              helperText={touched.address && errors.address}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained">
              Update
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdateVendor;
