import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '@material-ui/icons';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import { fDate, fDateTime } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import {
  getVendorsAction,
  logoutVendorAction,
  resetVendorPasswordAction,
  searchVendorsAction
} from '../../store/actions/VendorsActions';
import MoreMenu from '../../components/MoreMenu';
import UpdateVendor from './components/UpdateVendor';
import ViewPasswordInfo from './components/ViewPasswordResetInfo';
import TopupVendor from './components/TopupVendor';
import Table from '../../components/table';

function VendorsModule() {
  const dispatch = useDispatch();
  const { isLoading, VENDORS_LIST } = useSelector((state) => state.vendors);

  const [filterName, setFilterName] = useState('');
  const [debounceTimer, setDebounceTimer] = useState('');

  const [currentRow, setCurrentRow] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isTopupVendorOpen, setIsTopupVendorOpen] = useState(false);
  const [selectedVendorID, setSelectedVendorID] = useState(null);
  const [showPwdInfo, setShowPwdInfo] = useState(false);

  const [dataPageFetched, setDataPageFetched] = useState(1);

  useEffect(() => {
    dispatch(getVendorsAction({ page: 1 }));
  }, []);

  useEffect(() => {
    if (debounceTimer) clearTimeout(debounceTimer);

    const timer = setTimeout(() => {
      dispatch(searchVendorsAction(filterName));
    }, [1000]);
    setDebounceTimer(timer);
  }, [filterName]);

  const handleViewUpdateModal = (row) => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
    setCurrentRow(row);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleShowPwdInfo = (message) => {
    setShowPwdInfo(!showPwdInfo);
    setCurrentRow(message);
  };

  const handleClosePwdInfoModal = () => {
    setShowPwdInfo(false);
  };

  const handleResetVendorPassword = async (id) => {
    const action = window.confirm(`Are you sure you want to Reset this Vendor Password`);
    if (action) {
      const response = await dispatch(resetVendorPasswordAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      } else handleShowPwdInfo(response);
    }
  };

  const handleLogoutVendor = async (id) => {
    const action = window.confirm(`Are you sure you want to Logout this Vendor`);
    if (action) {
      const response = await dispatch(logoutVendorAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      }
    }
  };

  const handleTopupVendor = (id) => {
    setIsTopupVendorOpen(!isUpdateModalOpen);
    setSelectedVendorID(id);
  };
  const handleCloseTopupVendorModal = () => {
    setIsTopupVendorOpen(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'fullname',
      label: 'Name',
      align: 'left'
    },
    {
      id: 'phone',
      label: 'Phone',
      align: 'left'
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left'
    },
    {
      id: 'accessStatus',
      label: 'Views',
      align: 'left',
      render: (index, rowData) => (
        <Chip
          label={rowData.accessStatus == 1 ? 'Active' : 'Inactive'}
          color={rowData.accessStatus == 1 ? 'primary' : 'default'}
        />
      )
    },
    {
      id: 'referredBy',
      label: 'Referred By',
      align: 'left',
      render: (index, rowData) => (
        <>
          {rowData.referredBy || 'No one'}
          {rowData.referredBy && rowData?.parent_referral?.fullname}
        </>
      )
    },
    {
      id: 'childrenReferralsCount',
      label: 'Children Referrals Count',
      align: 'left',
      render: (index, rowData) => rowData.childrenReferralsCount || 0
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      align: 'left',
      render: (index, rowData) => fDate(rowData.dateCreated)
    },
    {
      id: 'action',
      label: 'Actions',
      align: 'left',
      render: (index, rowData) => (
        <MoreMenu
          topup
          edit
          reset
          logout
          handleLogout={() => handleLogoutVendor(rowData.id)}
          handleEdit={() => handleViewUpdateModal(rowData)}
          handleReset={() => handleResetVendorPassword(rowData.id)}
          handleTopupVendor={() => handleTopupVendor(rowData.id)}
        />
      )
    }
  ];

  const handleGetMoreData = () => {
    dispatch(getVendorsAction({ page: dataPageFetched + 1 }));
    setDataPageFetched((prev) => prev + 1);
  };
  return (
    <Page title="Vendors Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vendors
          </Typography>
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={VENDORS_LIST}
          isLoading={isLoading}
          filterField="fullname"
          tableTitle="Vendors"
          getMoreData={handleGetMoreData}
        />
      </Container>

      {/* modal */}
      <UpdateVendor
        open={isUpdateModalOpen}
        handleClose={handleCloseUpdateModal}
        rowData={currentRow}
      />
      <ViewPasswordInfo
        open={showPwdInfo}
        handleClose={handleClosePwdInfoModal}
        messageObj={currentRow}
      />
      <TopupVendor
        open={isTopupVendorOpen}
        handleClose={handleCloseTopupVendorModal}
        vendorID={selectedVendorID}
      />
    </Page>
  );
}

export default VendorsModule;
