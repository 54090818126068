import axios from './index';

export const getPriceRequest = (values) =>
  axios.get(
    `/v1/admins/get/prices/-/${values?.vendorID || '-'}/${values?.status || '-'}/${
      values?.from || '-'
    }/${values?.to || '-'}/-/-`
  );

export const createPriceRequest = (reqObj) => axios.post('/v1/admins/create/price', reqObj);

export const disablePriceRequest = (priceID) => axios.put(`/v1/admins/disable/price/${priceID}`);

export const getConstantsRequest = (values) => axios.get(`/v1/admins/iranlowos/get/constants`);
