import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormControl, InputLabel, Select, MenuItem, Stack, Grid } from '@material-ui/core';
import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { getAdAction } from 'src/store/actions/AdActions';
import { sortArrayBy } from 'src/utils/utilities';
import { getWalletAction } from 'src/store/actions/WalletActions';
import { getAdminsAction } from 'src/store/actions/AdminActions';

function FilterLog({ handleFilter, loading, page }) {
  const dispatch = useDispatch();
  const { VENDORS_LIST } = useSelector((state) => state.vendors);
  const { ADMIN_LIST } = useSelector((state) => state.admins);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(getVendorsAction());
    dispatch(getAdminsAction());
  }, []);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      vendorID: '',
      adminID: '',
      from: '',
      to: ''
    },
    validationSchema: Yup.object().shape({
      //   vendorID: Yup.string().required('Vendor is required')
    }),
    onSubmit: (values) => {
      const fdate = moment(fromDate).unix();
      const tdate = moment(toDate).unix();
      handleFilter({ ...values, to: tdate, from: fdate });
    }
  });

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     const fdate = moment(fromDate).unix();
  //     const tdate = moment(toDate).unix();
  //     handleFilter({ to: tdate, from: fdate });
  //   };

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          {page === 'vendor' && (
            <Grid item sm={4}>
              <TextField
                error={Boolean(touched.vendorID && errors.vendorID)}
                helperText={touched.vendorID && errors.vendorID}
                fullWidth
                select
                margin="normal"
                name="vendorID"
                label="Vendor"
                variant="outlined"
                onBlur={handleBlur}
                size="small"
                onChange={handleChange}
                value={values.vendorID}
              >
                <MenuItem value="">Please select Vendor</MenuItem>
                {Boolean(VENDORS_LIST) &&
                  sortArrayBy(VENDORS_LIST, 'fullname').map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.fullname}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}

          {page === 'admin' && (
            <Grid item sm={4}>
              <TextField
                error={Boolean(touched.adminID && errors.adminID)}
                helperText={touched.adminID && errors.adminID}
                fullWidth
                select
                margin="normal"
                name="adminID"
                label="Admin"
                variant="outlined"
                onBlur={handleBlur}
                size="small"
                onChange={handleChange}
                value={values.adminID}
              >
                <MenuItem value="">Please select Admin</MenuItem>
                {Boolean(ADMIN_LIST) &&
                  sortArrayBy(ADMIN_LIST, 'fullname').map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.fullname}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          <Grid item sm={4}>
            <TextField
              autoFocus
              margin="normal"
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              //   onBlur={handleBlur}
              //   error={Boolean(touched.from && errors.from)}
              //   helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={4}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              onChange={(e) => setToDate(e.target.value)}
              //   onBlur={handleBlur}
              //   error={Boolean(touched.to && errors.to)}
              //   helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterLog;
