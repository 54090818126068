import { getDayBefore, getTodayDate } from 'src/utils/utilities';
import axios from './index';

export const getDevicesRequest = (values) =>
  axios.get(
    `/v1/admins/devices/get/devices/-/${values?.serial || '-'}/${values?.busID || '-'}/${
      values?.routeID || '-'
    }/${values?.page || '-'}/${values?.limit || '-'}/${values?.from || '-'}/${values?.to || '-'}/${
      values?.countryID || '-'
    }/${values?.stateID || '-'}/${values?.cityID || '-'}/${values?.activeStatus || '-'}`
  );

export const createDeviceRequest = (reqObj) =>
  axios.post('/v1/admins/devices/create/device', reqObj);

export const updateDeviceRequest = (values) =>
  axios.put(`/v1/admins/devices/update/device/${values.data.id}`, values);

export const assignDeviceRequest = (reqObj) =>
  axios.post('/v1/admins/devices/device/assign/places', reqObj);

export const toggleDeviceStatusRequest = (id, status) =>
  axios.put(`/v1/admins/devices/toggle/device/${id}/active-status/${status}`);

export const getDeviceViewsRequest = (values) =>
  axios.get(
    `/v1/admins/devices/get/device-action-counts/${values?.deviceID || '-'}/${
      values?.fromDate || getDayBefore()
    }/${values?.toDate || getTodayDate()}`
  );
