import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateDeviceAction } from 'src/store/actions/DevicesActions';

function UpdateDevice({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rowData) {
      const { name, serial, busID, id, phone } = rowData;
      setFieldValue('id', id);
      setFieldValue('name', name);
      setFieldValue('serial', serial);
      setFieldValue('busID', busID);
      setFieldValue('phone', phone);
    }
  }, [rowData]);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        id: '',
        name: '',
        serial: '',
        busID: '',
        phone: ''
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required('Required'),
        serial: Yup.string().required('Required'),
        busID: Yup.string().required('Required'),
        phone: Yup.string().required('Required')
      }),
      onSubmit: async (values) => {
        setLoading(true);
        const response = await dispatch(updateDeviceAction(values));
        setLoading(false);
        if (response?.error === 1) {
          toast.error(`${response.message}`);
        } else handleClose();
      }
    });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Device</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              margin="normal"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              margin="normal"
              id="serial"
              label="Serial"
              type="text"
              fullWidth
              value={values.serial}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.serial && errors.serial)}
              helperText={touched.serial && errors.serial}
            />

            <TextField
              margin="normal"
              id="busID"
              label="Bus ID"
              type="text"
              fullWidth
              value={values.busID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.busID && errors.busID)}
              helperText={touched.busID && errors.busID}
            />

            <TextField
              margin="normal"
              id="phone"
              label="Phone Number"
              type="phone"
              fullWidth
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Update Device
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdateDevice;
