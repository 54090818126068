import { GET_TRANSACTIONS, LOAD_REQUEST, LOAD_DONE, GET_ROUTES } from '../types';

const initialState = {
  ROUTES_LIST: [],
  isLoading: false
};

export const RoutesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_ROUTES: {
      return { ...state, ROUTES_LIST: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
