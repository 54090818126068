import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import AdsModule from './pages/AdsModule';
import TopupModule from './pages/TopupModule';
import TransactionsModule from './pages/TransactionsModule';
import ActivityLogModule from './pages/ActivityLog';
import VendorModule from './pages/VendorModule';
import WalletModule from './pages/WalletModule';
import AdminModule from './pages/AdminModule';
import PriceModule from './pages/PriceModule';
import DevicesModule from './pages/DevicesModule';
import ReportsModule from './pages/ReportsModule';
import ConversionModule from './pages/Conversion';
import PricePlan from './pages/PricePlan';
import RoutesModule from './pages/RoutesModule';
import DeviceHistoryModule from './pages/DeviceHistoryModule';
import Customers from './pages/Customers';
// ----------------------------------------------------------------------

const routes = [
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard/app" replace /> },
      { path: 'app', element: <DashboardApp /> },
      { path: 'ads', element: <AdsModule /> },
      { path: 'topup', element: <TopupModule /> },
      { path: 'routes', element: <RoutesModule /> },
      { path: 'transactions', element: <TransactionsModule /> },
      { path: 'activitylog', element: <ActivityLogModule /> },
      { path: 'vendors', element: <VendorModule /> },
      { path: 'wallet', element: <WalletModule /> },
      { path: 'admin', element: <AdminModule /> },
      { path: 'user', element: <User /> },
      { path: 'products', element: <Products /> },
      { path: 'price', element: <PriceModule /> },
      { path: 'blog', element: <Blog /> },
      { path: 'devices', element: <DevicesModule /> },
      { path: 'device-history', element: <DeviceHistoryModule /> },
      { path: 'reports', element: <ReportsModule /> },
      { path: 'conversions', element: <ConversionModule /> },
      { path: 'pplan', element: <PricePlan /> },
      { path: 'customers', element: <Customers /> },
      { path: '*', element: <Navigate to="/dashboard" /> }
    ]
  },
  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }

  // { path: '*', element: <Navigate to="/404" replace /> }
];

export default function Router() {
  const token = sessionStorage.getItem('token');
  const AUTHENTICATED_ROUTES = useRoutes([routes[0]]);
  const UNAUTHENTICATED_ROUTES = useRoutes([routes[1]]);
  const element = token ? AUTHENTICATED_ROUTES : UNAUTHENTICATED_ROUTES;
  return element;
}
