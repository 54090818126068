import React, { useState } from 'react';
import { Card, TableContainer, LinearProgress, TablePagination } from '@material-ui/core';

import CustomTable from './CustomTable';
import { getTableHeader, getTableData } from '../../utils/utilities';
import { UserListToolbar } from '../_dashboard/user';
import Scrollbar from '../Scrollbar';

export default function Table({
  tableHeader,
  data,
  isLoading,
  searchPlaceholder = '',
  filterField = '',
  tableTitle = '',
  getMoreData = () => {}
}) {
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    const totalPages = Math.floor(data.length / rowsPerPage);
    const lastPage = totalPages;
    console.log({ newPage, lastPage, totalPages });
    if (newPage === totalPages - 1) {
      getMoreData();
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  return (
    <div>
      <Card>
        <UserListToolbar
          title={tableTitle}
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder={searchPlaceholder}
          tableHeader={getTableHeader(tableHeader)}
          tableData={getTableData(data, tableHeader)}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {isLoading && <LinearProgress />}
            <CustomTable
              DATALIST={data}
              TABLE_HEAD={tableHeader}
              filterName={filterName}
              filterField={filterField}
              page={page}
              rowsPerPage={rowsPerPage}
            />
            {isLoading && <LinearProgress />}
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
}
