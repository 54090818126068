import { getDeviceHistoryRequest } from 'src/api/DeviceHistoryRequest';

import { DEVICE_HISTORY_EMPTY, GET_DEVICE_HISTORY, LOAD_DONE, LOAD_REQUEST } from '../types';

export const getDeviceHistoryAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getDeviceHistoryRequest(values);

    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    if (data?.content?.data == null) {
      dispatch({ type: DEVICE_HISTORY_EMPTY });
      return { error: 1, message: 'No results found' };
    }

    dispatch({ type: GET_DEVICE_HISTORY, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
