import React, { useEffect, useState } from 'react';
import { Stack, Container, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import { fDate } from '../../utils/formatTime';
import { getDeviceHistoryAction } from '../../store/actions/DeviceHistoryActions';
import { getDayBefore, getTodayDate } from '../../utils/utilities';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import FilterCustomers from './components/FilterCustomers';
import { getCustomersAction } from 'src/store/actions/CustomersActions';
import Table from '../../components/table';


function Customers() {
  const dispatch = useDispatch();
  const { isLoading, customers } = useSelector((state) => state.customers);
  const [dataPageFetched, setDataPageFetched] = useState(1);

  const [filterLoader, setFilterLoader] = useState(false);

  useEffect(() => {
    dispatch(getCustomersAction({ page: 1 }));
  }, []);

  //   table head
  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'phone',
      label: 'Phone Number',
      align: 'left'
    },
    {
      id: 'ageRange',
      label: 'Age Range',
      align: 'left'
    },
    {
      id: 'gender',
      label: 'Gender',
      align: 'left'
    },
    {
      id: 'dateCreated',
      label: 'Date',
      render: (index, rowData) => (rowData.dateCreated ? fDate(rowData.dateCreated) : null),
      align: 'left'
    }
  ];

  const _filterDevices = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getCustomersAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  const handleGetMoreData = () => {
    dispatch(getCustomersAction({ page: dataPageFetched + 1 }));
    setDataPageFetched((prev) => prev + 1);
  };

  return (
    <Page title="Customers">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterCustomers handleFilter={_filterDevices} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={customers}
          isLoading={isLoading}
          filterField="name"
          tableTitle="Customers"
          getMoreData={handleGetMoreData}
        />
      </Container>
    </Page>
  );
}

export default Customers;
