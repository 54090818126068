import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Stack
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@material-ui/lab';
import { createAdminAction, updateAdminAction } from '../../../store/actions/AdminActions';
import { modules } from './NewAdmin';

function UpdateAdmin({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.admins);

  const { handleChange, handleSubmit, errors, touched, handleBlur, values, setFieldValue } =
    useFormik({
      initialValues: {
        fullname: ``,
        username: ``,
        phone: ``,
        email: ``,
        address: ``,
        privileges: []
      },
      validationSchema: Yup.object().shape({
        fullname: Yup.string().required('Fullname is required'),
        username: Yup.string().required('Username is required'),
        phone: Yup.string().required('Phone is required'),
        email: Yup.string().required('Email is required'),
        address: Yup.string().required('Address is required'),
        profilePicture: Yup.string().nullable()
      }),
      onSubmit: async (values) => {
        const response = await dispatch(
          updateAdminAction(rowData.id, {
            ...values
          })
        );
        if (response?.error === 1) {
          toast.error(`${response.message}`);
        } else handleClose();
      }
    });

  const handleCheckboxChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      setFieldValue('privileges', [...values.privileges, name]);
    } else {
      setFieldValue(
        'privileges',
        values.privileges.filter((v) => v !== name)
      );
    }
  };

  useEffect(() => {
    if (rowData) {
      const { fullname, email, username, phone, address, profilePicture, privileges } = rowData;
      const priviledgeArray = JSON.parse(privileges);

      setFieldValue('fullname', fullname);
      setFieldValue('username', username);
      setFieldValue('phone', phone);
      setFieldValue('email', email);
      setFieldValue('address', address);
      setFieldValue('profilePicture', profilePicture);
      setFieldValue('privileges', priviledgeArray);
    }
  }, [rowData]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Admin Information</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} gap={4}>
                <TextField
                  autoFocus
                  margin="normal"
                  id="fullname"
                  name="fullname"
                  label="Fullname"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullname}
                  error={Boolean(errors.fullname && touched.fullname)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  error={Boolean(errors.username && touched.username)}
                  helperText={touched.username && errors.username}
                />
              </Grid>

              <Grid item lg={6}>
                <TextField
                  id="phone"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={Boolean(errors.phone && touched.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={Boolean(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  error={Boolean(errors.address && touched.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>

              <Grid item lg={12}>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Assign your preferred routes to show this Ad</FormLabel> */}
                  <Stack direction="row">
                    <FormGroup style={{ border: 1 }}>
                      {modules.map((module) => (
                        <FormControlLabel
                          key={module.id}
                          control={
                            <Checkbox
                              checked={values.privileges.includes(module.value.toString())}
                              onChange={handleCheckboxChange}
                              name={module.value.toString()}
                              id={module.value.toString()}
                            />
                          }
                          label={module.name}
                        />
                      ))}
                    </FormGroup>
                  </Stack>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained">
              Update Admin
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdateAdmin;
