import { GET_TRANSACTIONS, LOAD_REQUEST, LOAD_DONE, EMPTY_LIST } from '../types';

const initialState = {
  TRANSACTIONS_LIST: [],
  isLoading: false
};

export const TransactionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_TRANSACTIONS: {
      return { ...state, TRANSACTIONS_LIST: payload, isLoading: false };
    }
    case EMPTY_LIST: {
      return { ...state, TRANSACTIONS_LIST: [], isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
