import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';
import { createPriceAction } from '../../../store/actions/PriceActions';
import { createDeviceAction, toggleDeviceStatusAction } from 'src/store/actions/DevicesActions';
import { ro } from 'faker/lib/locales';

function DeviceStatus({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.devices);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (values) => {
    setLoader(true);
    const status = rowData.activeStatus === 1 ? 0 : 1;
    const res = await dispatch(toggleDeviceStatusAction(rowData.id, status));
    setLoader(false);
    if (res?.error) {
      return toast.error(res.message);
    }
    handleClose();
    return toast.success('Successful');
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>Confirm Status</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the status of this device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            loading={loader}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeviceStatus;
