import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';
import { getVendorsAction } from '../../../store/actions/VendorsActions';

function TopupVendor({ open, handleClose, vendorID }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      amount: '',
      vendorID: vendorID
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required('Amount is required')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const response = await dispatch(createTopupAction({ ...values, vendorID }));
      setLoading(false);
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      } else handleClose();
    }
  });

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Topup Vendor's Wallet</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              id="amount"
              label="Amount"
              type="text"
              fullWidth
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Topup
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default TopupVendor;
