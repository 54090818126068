import { getAdminLogsRequest, getLogsRequest, getVendorLogsRequest } from '../../api/LogRequest';
import {
  LOAD_REQUEST,
  GET_LOGS,
  EMPTY_LIST,
  GET_ADMIN_LOGS,
  GET_VENDOR_LOGS,
  LOAD_DONE
} from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getLogsAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getLogsRequest(values);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    dispatch({ type: GET_LOGS, payload: data.content.data.all });
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};

export const getAdminLogsAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getAdminLogsRequest(values);
    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);

    dispatch({ type: GET_ADMIN_LOGS, payload: data.content.data.all });
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};

export const getVendorLogsAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getVendorLogsRequest(values);
    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);

    dispatch({ type: GET_VENDOR_LOGS, payload: data.content.data.all });
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};
