import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableRow, CircularProgress, Button } from '@material-ui/core';
import { filter } from 'lodash';
import { UserListHead } from '../_dashboard/user';
import SearchNotFound from '../SearchNotFound';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const CustomTable = ({
  DATALIST,
  TABLE_HEAD,
  filterName,
  filterField,
  page,
  rowsPerPage,
  isLoading
}) => {
  const [orderBy, setOrderBy] = useState('amount');
  const [order, setOrder] = useState('asc');

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DATALIST?.length) : 0;

  function applySortFilter(array, comparator, query, field) {
    if (array?.length > 0) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      if (query) {
        return filter(
          array,
          (_user) => _user?.[field]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }
      return stabilizedThis.map((el) => el[0]);
    }
  }

  const filterData = applySortFilter(
    DATALIST,
    getComparator(order, orderBy),
    filterName,
    filterField
  );

  const isDevicesFound = filterData?.length === 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Table>
      <UserListHead
        order={order}
        orderBy={orderBy}
        headLabel={TABLE_HEAD}
        rowCount={DATALIST?.length}
        onRequestSort={handleRequestSort}
      />

      <TableBody>
        {isLoading && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={12} style={{ textAlign: 'center' }}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
        {filterData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const { id } = row;

            return (
              <TableRow hover key={id} tabIndex={-1} role="checkbox">
                {TABLE_HEAD.map((column, i) => {
                  const value = row[column.id] ? row[column.id] : null;
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.render ? column.render(index, row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
      {isDevicesFound && (
        <TableBody>
          <TableRow>
            <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
              <SearchNotFound searchQuery={filterName} />
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  );
};

export default CustomTable;
