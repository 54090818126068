export const LOAD_REQUEST = 'LOAD_REQUEST';
export const LOAD_DONE = 'LOAD_DONE';
export const EMPTY_LIST = 'EMPTY_LIST';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

// Ad
export const GET_ADS = 'GET_ADS';

// transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';

// topup
export const GET_TOPUP = 'GET_TOPUP';

// logs
export const GET_LOGS = 'GET_LOGS';
export const GET_ADMIN_LOGS = 'GET_ADMIN_LOGS';
export const GET_VENDOR_LOGS = 'GET_VENDOR_LOGS';

// routes
export const GET_ROUTES = 'GET_ROUTES';

// wallet
export const GET_WALLET = 'GET_WALLET';

// vendors
export const GET_VENDORS = 'GET_VENDORS';

// admins
export const GET_ADMINS = 'GET_ADMINS';

export const GET_CURRENT_ADMIN = 'GET_CURRENT_ADMIN';

export const GET_PRICE = 'GET_PRICE';

export const GET_CONSTANTS = 'GET_CONSTANTS';

// Devices

export const GET_DEVICES = 'GET_DEVICES';
export const DEVICES_EMPTY = 'DEVICES_EMPTY';
export const GET_DEVICE_VIEWS = "GET_DEVICE_VIEWS";
export const DEVICE_VIEWS_EMPTY = "DEVICE_VIEWS_EMPTY";

// reports
export const GET_REPORTS = 'GET_REPORTS';

// conversion
export const GET_CONVERSION = 'GET_CONVERSION';

// price plan
export const GET_PRICE_PLAN = 'GET_PRICE_PLAN';

// location
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';

// device history
export const GET_DEVICE_HISTORY = 'GET_DEVICE_HISTORY';
export const DEVICE_HISTORY_EMPTY = 'DEVICE_HISTORY_EMPTY';

// customers history
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_EMPTY = 'GET_CUSTOMERS_EMPTY';
