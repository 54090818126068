import {
  EMPTY_LIST,
  GET_ADMIN_LOGS,
  GET_LOGS,
  GET_VENDOR_LOGS,
  LOAD_DONE,
  LOAD_REQUEST
} from '../types';

const initialState = {
  LOGLIST: [],
  ADMIN_LOG_LIST: [],
  VENDORS_LOG_LIST: [],
  isLoading: false
};

export const LogsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_LOGS: {
      return { ...state, LOGLIST: payload, isLoading: false };
    }
    case GET_ADMIN_LOGS: {
      return { ...state, ADMIN_LOG_LIST: payload, isLoading: false };
    }
    case GET_VENDOR_LOGS: {
      return { ...state, VENDORS_LOG_LIST: payload, isLoading: false };
    }
    case EMPTY_LIST: {
      return { ...state, ADMIN_LOG_LIST: [], isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
