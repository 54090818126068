import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';
import { createPriceAction } from '../../../store/actions/PriceActions';
import { createDeviceAction } from 'src/store/actions/DevicesActions';

function CreateDevice({ open, handleClose }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.devices);
  const [loader, setLoader] = useState(false);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      name: '',
      serial: '',
      busID: '',
      phone: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      serial: Yup.string().required('Required'),
      busID: Yup.string().required('Required'),
      phone: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      setLoader(true);
      const response = await dispatch(createDeviceAction(values));
      setLoader(false);
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      } else handleClose();
    }
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Device</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              margin="normal"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              margin="normal"
              id="serial"
              label="Serial"
              type="text"
              fullWidth
              value={values.serial}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.serial && errors.serial)}
              helperText={touched.serial && errors.serial}
            />

            <TextField
              margin="normal"
              id="busID"
              label="Bus ID"
              type="text"
              fullWidth
              value={values.busID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.busID && errors.busID)}
              helperText={touched.busID && errors.busID}
            />

            <TextField
              margin="normal"
              id="phone"
              label="Phone Number"
              type="phone"
              fullWidth
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loader}>
              Create Device
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default CreateDevice;
