import { getWalletRequest } from '../../api/WalletRequest';
import { GET_WALLET, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getWalletAction = (values = {}) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getWalletRequest(values);
    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    dispatch({ type: GET_WALLET, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
