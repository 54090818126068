import React, { useEffect } from 'react';
// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAction } from '../store/actions/DashboardActions';
// components
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  WalletInfo
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardAction());
  }, []);

  return (
    <Page title="Dashboard ">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo value={dashboard?.totalTopupSum} title="Total Toppup" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo value={dashboard?.totalSelfTopupSum} title="Self Toppup" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo value={dashboard?.totalSystemTopupSum} title="System Toppup" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalRoutes} title="Total Routes" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.vendorsCount} title="Total Vendors" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalAds} title="Total Ads" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalActiveAds} title="Active Ads" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalInactiveAds} title="Inactive Ads" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalBlockedAds} title="Blocked Ads" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalViews} title="Total Ad Views" />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
