import { toast } from 'react-toastify';
import {
  assignDeviceRequest,
  createDeviceRequest,
  getDevicesRequest,
  getDeviceViewsRequest,
  toggleDeviceStatusRequest,
  updateDeviceRequest
} from '../../api/DevicesRequest';

import {
  DEVICE_VIEWS_EMPTY,
  DEVICES_EMPTY,
  GET_DEVICE_VIEWS,
  GET_DEVICES,
  LOAD_DONE,
  LOAD_REQUEST
} from '../types';
import { checkIfResponseOk } from './AuthActions';
import { initialDeviceCache } from 'src/pages/DevicesModule';

export const getDevicesAction = (values, more) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const { DEVICES_LIST } = getState().devices;
    const { data } = await getDevicesRequest(values);

    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    if (data?.content?.data == null) {
      dispatch({ type: DEVICES_EMPTY });
      return { error: 1, message: 'No results found' };
    }

    if (more === 'getMore') {
      dispatch({
        type: GET_DEVICES,
        payload: [...DEVICES_LIST, ...data.content.data.all]
      });
      return 0;
    }
    dispatch({ type: GET_DEVICES, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createDeviceAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await createDeviceRequest(reqObj);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!');
    dispatch(getDevicesAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateDeviceAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const reqObj = {
      data: {
        ...values
      }
    };
    const { data } = await updateDeviceRequest(reqObj);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      toast.info(data?.error?.message);
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Updated Sucessfully!');
    dispatch(getDevicesAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const assignDevicesAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await assignDeviceRequest(payload);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);
    toast.success('Assigned Sucessfully!');
    dispatch(getDevicesAction());
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};

export const toggleDeviceStatusAction = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await toggleDeviceStatusRequest(id, status);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);
    toast.success('Sucessful!');
    dispatch(getDevicesAction(initialDeviceCache));
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};

export const getDeviceViewsAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getDeviceViewsRequest(values);

    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    if (data?.content?.data == null) {
      dispatch({ type: DEVICE_VIEWS_EMPTY });
      return { error: 1, message: 'No results found' };
    }

    dispatch({ type: GET_DEVICE_VIEWS, payload: data.content.data });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
