import { getCustomersRequest } from 'src/api/CustomersRequest';
import { GET_CUSTOMERS_EMPTY, GET_CUSTOMERS, LOAD_DONE, LOAD_REQUEST } from '../types';

export const getCustomersAction =
  (values = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOAD_REQUEST });

      const { data } = await getCustomersRequest({ ...values });

      if (data?.error?.status >= 400) {
        dispatch({ type: LOAD_DONE });
        return { error: 1, message: data?.error?.message };
      }

      if (data?.content?.data == null) {
        dispatch({ type: GET_CUSTOMERS_EMPTY });
        return { error: 1, message: 'No results found' };
      }

      dispatch({ type: GET_CUSTOMERS, payload: data.content.data.all });
      return 0;
    } catch (err) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: err.message };
    }
  };
