import axios from './index';

// export const getAdsRequest = (values) =>
//   axios.get(`/v1/admins/get/ads/-/${values?.routeID || '-'}/${values?.vendorID || '-'}/-/-`);

export const getAdsRequest = (values) =>
  axios.get(
    `/v1/admins/get/ads/-/${values?.routeID || '-'}/${values?.vendorID || '-'}/${
      values?.page || '1'
    }/${values?.limit || '100'}/${values?.countryID || '-'}/${values?.stateID || '-'}/${
      values?.cityID || '-'
    }/${values?.busID || '-'}/${values?.deviceID || '-'}/${values?.adType || '-'}/${
      values?.accessStatus || '-'
    }/${values?.dateFrom || '-'}/${values?.dateTo || '-'}`
  );

// export const createAdsRequest = (reqObj) => axios.post('/v1/vendors/create/ad', reqObj);

// export const updateAdRequest = (id, reqObj) => axios.put(`/v1/vendors/update/ad/${id}`, reqObj);

export const toggleStatusRequest = (id, status) =>
  axios.put(`/v1/admins/toggle/ad/${id}/active-status/${status}`);

// export const deleteAdRequest = (id) => axios.delete(`/v1/vendors/delete/ad/${id}`);

// export const unassignRouteRequest = (id, routeID) =>
//   axios.delete(`/v1/vendors/unassign/ad/${id}/route/${routeID}`);

// export const assignRouteRequest = (reqObj) => axios.post(`/v1/vendors/assign/ad/to/routes`, reqObj);

// wallet
// export const getRoutesRequest = () => axios.get(`v1/vendors/get/routes/-/-`);
