import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';
import { getVendorsAction } from '../../../store/actions/VendorsActions';
import { createPriceAction, getConstantsAction } from '../../../store/actions/PriceActions';

function NewPrice({ open, handleClose }) {
  const dispatch = useDispatch();
  const { isLoading, constants } = useSelector((state) => state.prices);
  const { VENDORS_LIST } = useSelector((state) => state.vendors);

  useEffect(() => {
    dispatch(getVendorsAction());
    dispatch(getConstantsAction());
  }, []);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      price: '',
      vendorID: '',
      eventType: ''
    },
    validationSchema: Yup.object().shape({
      price: Yup.string().required('Price is required'),
      vendorID: Yup.string(),
      eventType: Yup.string()
    }),
    onSubmit: async (values) => {
      const response = await dispatch(createPriceAction(values));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      } else handleClose();
    }
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Ad Price</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Set Price per Ad either for all Vendors or a Specific Vendor
            </DialogContentText>
            <TextField
              error={Boolean(touched.vendorID && errors.vendorID)}
              helperText={touched.vendorID && errors.vendorID}
              fullWidth
              select
              margin="normal"
              name="vendorID"
              label="Vendor"
              required
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.vendorID}
            >
              <MenuItem value="">Please select Vendor</MenuItem>
              <MenuItem value="null">All Vendors</MenuItem>
              {VENDORS_LIST.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.fullname}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              error={Boolean(touched.eventType && errors.eventType)}
              helperText={touched.eventType && errors.eventType}
              fullWidth
              select
              margin="normal"
              name="eventType"
              label="Event"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.eventType}
            >
              <MenuItem value="">Please select Event</MenuItem>
              {constants.map((item, index) => (
                <MenuItem key={index} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              autoFocus
              margin="normal"
              id="price"
              label="Price"
              type="text"
              fullWidth
              value={values.price}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
              Create Price
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default NewPrice;
