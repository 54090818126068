import { toast } from 'react-toastify';
import {
  createAdminRequest,
  getAdminRequest,
  getAdminsRequest,
  logoutAdminRequest,
  resetAdminPasswordRequest,
  updateAdminPasswordRequest,
  updateAdminRequest
} from '../../api/AdminRequest';
import { GET_ADMINS, GET_CURRENT_ADMIN, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getAdminsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getAdminsRequest();

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    dispatch({ type: GET_ADMINS, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const getCurrentAdminAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getAdminRequest();

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    dispatch({ type: GET_CURRENT_ADMIN, payload: data.content.data });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createAdminAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await createAdminRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdminsAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateAdminAction = (adminID, obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await updateAdminRequest(adminID, reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Updated Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getCurrentAdminAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const resetAdminPasswordAction = (adminID) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await resetAdminPasswordRequest(adminID);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    toast.success('Password Reset Sucessful!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    dispatch(getAdminsAction());
    return data.success;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const logoutAdminAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await logoutAdminRequest(id);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    toast.success('Admin Logged out Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    dispatch(getAdminsAction());
    return data.success;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateAdminPasswordAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await updateAdminPasswordRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Password Updated Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getCurrentAdminAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
