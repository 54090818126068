import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListToolbar } from '../../components/_dashboard/user';
import { fDate } from '../../utils/formatTime';
import CustomTable from '../../components/table/CustomTable';
import { getRoutesAction } from 'src/store/actions/RoutesActions';
import AssignRoutes from './components/AssignRoutes';
import Table from '../../components/table';

function RoutesModule() {
  const dispatch = useDispatch();
  const { isLoading, ROUTES_LIST } = useSelector((state) => state.routes);

  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [unassign, setUnassignFlag] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [dataPageFetched, setDataPageFetched] = useState(1);

  useEffect(() => {
    dispatch(getRoutesAction({ page: 1, limit: 100 }));
  }, []);

  const toggleAssignModal = (rowData, unassignFlag) => {
    if (rowData) setRowData(rowData);
    if (unassignFlag) setUnassignFlag(true);
    if (!unassignFlag) setUnassignFlag(false);
    setOpenAssignModal(!openAssignModal);
  };

  //   table head
  const TABLE_HEAD = [
    { id: 'serial', label: 'S/N', render: (index, row) => index + 1 },
    { id: 'name', label: 'Route', align: 'left' },
    { id: 'source', label: 'Source', align: 'left' },
    {
      id: 'countries',
      label: 'Country',
      align: 'left',
      render: (index, row) => (
        <p>
          {row.countries.map((item, index) => {
            return <p> {item.name} </p>;
          })}
        </p>
      )
    },
    {
      id: 'states',
      label: 'State',
      align: 'left',
      render: (index, row) => (
        <p>
          {row.states.map((item, index) => {
            return <p> {item.name} </p>;
          })}
        </p>
      )
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      render: (index, rowData) => (rowData.dateCreated ? fDate(rowData.dateCreated) : null),
      align: 'left'
    },
    {
      id: 'actions',
      label: 'Actions',
      render: (index, rowData) => (
        <>
          <Button variant="contained" onClick={() => toggleAssignModal(rowData)}>
            Assign Route
          </Button>
          <Button onClick={() => toggleAssignModal(rowData, true)}>Unassign Route</Button>
        </>
      ),
      align: 'left'
    }
  ];

  const handleGetMoreData = () => {
    dispatch(getRoutesAction({ page: dataPageFetched + 1 }));
    setDataPageFetched((prev) => prev + 1);
  };

  return (
    <Page title="Routes Module">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Routes
          </Typography>
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={ROUTES_LIST}
          isLoading={isLoading}
          filterField="name"
          tableTitle="Routes"
          getMoreData={handleGetMoreData}
        />

        {/* Modals */}
        <AssignRoutes
          open={openAssignModal}
          handleClose={toggleAssignModal}
          rowData={rowData}
          unassignFlag={unassign}
        />
      </Container>
    </Page>
  );
}

export default RoutesModule;
