import { getTransactionRequest } from '../../api/TransactionsRequest';
import { LOAD_REQUEST, GET_TRANSACTIONS, LOAD_DONE, EMPTY_LIST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getTransactonAction =
  (values = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOAD_REQUEST });

      const { data } = await getTransactionRequest(values);
      if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
        dispatch({ type: EMPTY_LIST });
        return { error: 1, message: data?.error?.message };
      } else if (data?.error?.code === '1') {
        dispatch({ type: LOAD_DONE });
        return { error: 1, message: data?.error?.message };
      }
      // checkIfResponseOk(data);

      dispatch({ type: GET_TRANSACTIONS, payload: data.content.data.all });
      return 0;
    } catch (err) {
      return { error: 1, message: err.message };
    }
  };
