import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stack from '@material-ui/core/Stack';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { baseURL } from '../../../api';

function ViewMedia({ open, handleClose, rowData }) {
  const dispatch = useDispatch();

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ad Medias</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                id="views"
                label="Reach (Views)"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.views}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="clicks"
                label="Clicks"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.clicks}
                disabled
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                id="currDailyCost"
                label="Daily Cost"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.currDailyCost}
                disabled
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                id="conversions"
                label="Conversions"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.conversions}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="ctr"
                label="CTR"
                variant="outlined"
                fullWidth
                margin="dense"
                value={Math.round((rowData?.clicks / rowData?.views) * 100 || 0)}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="maxDailyLimit"
                label="Max Daily Limit"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.maxDailyLimit || 0}
                disabled
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="conversionUrl"
                label="Conversion Url"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.conversionUrl}
                disabled
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="caption"
                label="Caption"
                variant="outlined"
                fullWidth
                margin="dense"
                value={rowData?.caption}
              />
            </Grid>
          </Grid>

          {rowData?.ad_medias.length > 0 ? (
            <Carousel centerMode>
              {rowData.ad_medias.map((item, index) => {
                const isHttpUrl = item.media.substring(0, 4).toLowerCase();
                if (item.mediaType === 'IMAGE') {
                  return (
                    <img
                      src={isHttpUrl === 'http' ? `${item.media}` : `${baseURL}/${item.media}`}
                      key={index}
                      alt={item.media}
                      // alt={`${imgBase}/${item.timeline_media}`}
                    />
                  );
                }
                return (
                  <video
                    src={isHttpUrl === 'http' ? `${item.media}` : `${baseURL}/${item.media}`}
                    autoPlay
                    controls
                    key={index}
                  >
                    <track default kind="captions" srcLang="en" />
                    <source src={`${item.media}`} type="video/mp4" />
                    <source src={`${item.media}`} type="video/ogg" />
                    <source src={`${item.media}`} type="video/avi" />
                  </video>
                );
              })}
            </Carousel>
          ) : (
            <h4 style={{ textAlign: 'center' }}>No media available...</h4>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewMedia;
