import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton, LocalizationProvider } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  FormControl,
  InputLabel,
  Select,
  Stack,
  Grid,
  IconButton,
  InputAdornment,
  Autocomplete,
  MenuItem
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getCitiesAction,
  getCountriesAction,
  getStatesAction
} from 'src/store/actions/WorldActions';

function FilterDevices({ handleFilter, loading, isDevice }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({ country: [], state: [], city: [] });
  const { countries, states, cities } = useSelector((state) => state.world);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DDThh:mm'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DDThh:mm'));
  const [viewCountFrom, setViewCountFrom] = useState(
    moment().subtract(1, 'd').format('YYYY-MM-DDThh:mm')
  );
  const [viewCountTo, setViewCountTo] = useState(moment().format('YYYY-MM-DDThh:mm'));

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        serial: '',
        busID: '',
        from: moment().subtract(1, 'd').format('YYYY-MM-DDThh:mm') || '',
        to: moment().format('YYYY-MM-DDThh:mm') || '',
        countryID: '',
        countryLabel: '',
        stateID: '',
        stateLabel: '',
        cityID: '',
        cityLabel: '',
        limit: '25',
        page: 1,
        activeStatus: 1,
        viewCountFrom: moment().subtract(1, 'd').format('YYYY-MM-DDThh:mm') || '',
        viewCountTo: moment().format('YYYY-MM-DDThh:mm') || ''
      },
      onSubmit: (values) => {
        const fdate = moment(fromDate).unix();
        const tdate = moment(toDate).unix();
        const vcfdate = moment(viewCountFrom).unix();
        const vctdate = moment(viewCountTo).unix();
        handleFilter({
          ...values,
          from: fdate,
          to: tdate,
          viewCountFrom: vcfdate,
          viewCountTo: vctdate
        });
      }
    });

  const handleClear = (key) => {
    // e.stopPropagation();

    if (key === 'fromDate') {
      setFieldValue('from', '');
      setFromDate('');
    }
    if (key === 'toDate') {
      setFieldValue('to', '');
      setToDate('');
    }
    if (key === 'viewCountFrom') {
      setFieldValue('viewCountFrom', '');
      setViewCountFrom('');
    }
    if (key === 'viewCountTo') {
      setFieldValue('viewCountTo', '');
      setViewCountTo('');
    }
  };

  useEffect(() => {
    dispatch(getCountriesAction());
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      const countryOptions = countries.map((item) => ({
        label: item.name,
        id: item.id
      }));
      const stateOptions = states.map((item) => ({
        label: item.name,
        id: item.id
      }));
      const cityOptions = cities.map((item) => ({
        label: item.name,
        id: item.id
      }));

      countryOptions.sort((a, b) => a.label.localeCompare(b.label));
      stateOptions.sort((a, b) => a.label.localeCompare(b.label));
      cityOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions((prev) => ({
        country: [...countryOptions],
        state: [...stateOptions],
        city: [...cityOptions]
      }));
    }
  }, [countries, states, cities]);

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="serial"
              label="Serial No."
              type="text"
              fullWidth
              value={values.serial}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.serial && errors.serial)}
              helperText={touched.serial && errors.serial}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="busID"
              label="Bus ID"
              type="text"
              fullWidth
              value={values.busID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.busID && errors.busID)}
              helperText={touched.busID && errors.busID}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="countryID"
              options={options.country}
              name="countryID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('countryID', newValue?.id || null);
                setFieldValue('countryLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getStatesAction({ countryID: newValue.id }));
                }
              }}
              fullWidth
              value={values.countryLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="Country" />}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="stateID"
              options={options.state}
              name="stateID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('stateID', newValue?.id || null);
                setFieldValue('stateLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getCitiesAction({ stateID: newValue.id }));
                }
              }}
              fullWidth
              value={values.stateLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="State" />}
            />
          </Grid>

          {/* <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="cityID"
              options={options.city}
              // sx={{ width: 300 }}
              name="cityID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('cityID', newValue?.id || null);
                setFieldValue('cityLabel', newValue?.label);
              }}
              fullWidth
              value={values.cityLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="City" />}
            />
          </Grid> */}

          <Grid item sm={12} md={3}>
            <TextField
              margin="normal"
              id="fromDate"
              label="From Date"
              type="datetime-local"
              fullWidth
              value={values.from}
              defaultValue={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                setFieldValue('from', e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.from && errors.from)}
              helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear('fromDate')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="datetime-local"
              fullWidth
              defaultValue={toDate}
              value={values.to}
              onChange={(e) => {
                setToDate(e.target.value);
                setFieldValue('to', e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.to && errors.to)}
              helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear('toDate')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="limit"
              label="List Count"
              type="text"
              fullWidth
              value={values.limit}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.limit && errors.limit)}
              helperText={touched.limit && errors.limit}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              error={Boolean(touched.activeStatus && errors.activeStatus)}
              helperText={touched.activeStatus && errors.activeStatus}
              fullWidth
              select
              id="activeStatus"
              margin="normal"
              name="activeStatus"
              label="Device Status"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.activeStatus}
            >
              <MenuItem value="-">Select Status</MenuItem>
              <MenuItem key={1} value={1}>
                Active
              </MenuItem>
              <MenuItem key={0} value={0}>
                Inactive
              </MenuItem>
            </TextField>
          </Grid>

          {/* <Grid item sm={12} md={3}>
            <TextField
              margin="normal"
              id="viewCountFrom"
              label="View Count From"
              type="datetime-local"
              fullWidth
              value={values.viewCountFrom}
              defaultValue={viewCountFrom}
              onChange={(e) => {
                setViewCountFrom(e.target.value);
                setFieldValue("viewCountFrom", e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.viewCountFrom && errors.viewCountFrom)}
              helperText={touched.viewCountFrom && errors.viewCountFrom}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear("viewCountFrom")}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="viewCountTo"
              label="View Count To"
              type="datetime-local"
              fullWidth
              defaultValue={viewCountTo}
              value={values.viewCountTo}
              onChange={(e) => {
                setViewCountTo(e.target.value);
                setFieldValue("viewCountTo", e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.viewCountTo && errors.viewCountTo)}
              helperText={touched.viewCountTo && errors.viewCountTo}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear("viewCountTo")}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}

          <Grid sm={3}>
            <LoadingButton
              style={{ marginTop: 40, marginLeft: 20 }}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterDevices;
