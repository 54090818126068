import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListToolbar } from '../../components/_dashboard/user';
import { fDate } from '../../utils/formatTime';
import CustomTable from '../../components/table/CustomTable';

import moment from 'moment';
import FilterDeviceHistory from './components/FilterDeviceHistory';
import { getDeviceHistoryAction } from 'src/store/actions/DeviceHistoryActions';
import { getDayBefore, getTodayDate } from '../../utils/utilities';
import Table from '../../components/table';

function DeviceHistoryModule() {
  const dispatch = useDispatch();
  const { isLoading, DEVICE_HISTORY } = useSelector((state) => state.devicehistory);

  const [filterLoader, setFilterLoader] = useState(false);

  useEffect(() => {
    const filterParams = {
      limit: 20,
      dateFrom: getDayBefore(),
      dateTo: getTodayDate()
    };
    dispatch(getDeviceHistoryAction(filterParams));
  }, []);

  //   table head
  const TABLE_HEAD = [
    { id: 'devicehistory', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'name',
      label: 'Device Name',
      align: 'left',
      render: (index, row) => (row?.device?.name ? row?.device?.name : 'nil')
    },
    {
      id: 'serial',
      label: 'Serial Number',
      align: 'left',
      // render: (index, row) =>  (<p>{row?.device?.serial || 'nil'}</p>)
      render: (index, row) => (row?.device?.serial ? row?.device?.serial : 'nil')
    },
    {
      id: 'busID',
      label: 'Bus ID',
      align: 'left'
      // render: (index, row) => row?.busID ? <p>{row?.busID || 'nil'}</p> : null
    },
    {
      id: 'lastseen',
      label: 'Last Seen',
      align: 'left',
      render: (index, row) =>
        row?.device?.lastseen ? (
          moment(row?.device?.lastseen * 1000).format('Do MMMM YYYY h:mm a')
        ) : (
          <Chip label="Inactive" color="info" />
        )
    },
    {
      id: 'views',
      label: 'Device Views',
      align: 'left',
      render: (index, row) => (row?.device?.views ? row?.device?.views : 'nil')
    },
    {
      id: 'routename',
      label: 'Route Name',
      align: 'left',
      render: (index, row) => (row?.route?.name ? row?.route?.name : 'nil')
    },
    {
      id: 'countries',
      label: 'Country',
      align: 'left',
      render: (index, row) =>
        row?.route?.countries ? (
          <p>
            {row?.route?.countries?.map((item, index) => {
              return <p> {item?.name} </p>;
            })}
          </p>
        ) : (
          'nil'
        )
    },
    {
      id: 'states',
      label: 'State',
      align: 'left',
      render: (index, row) =>
        row?.route?.states ? (
          <p>
            {row?.route?.states?.map((item, index) => {
              return item?.name ? <p> {item?.name} </p> : null;
            })}
          </p>
        ) : (
          'nil'
        )
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      render: (index, rowData) => (rowData?.dateCreated ? fDate(rowData?.dateCreated) : 'nil'),
      align: 'left'
    },
    {
      id: 'dateUpdated',
      label: 'Date Updated',
      render: (index, rowData) => (rowData?.dateUpdated ? fDate(rowData?.dateUpdated) : 'nil'),
      align: 'left'
    }
  ];

  const _filterDevices = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getDeviceHistoryAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  return (
    <Page title="Device History Module">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Device History
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterDeviceHistory handleFilter={_filterDevices} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={DEVICE_HISTORY}
          isLoading={isLoading}
          filterField="busID"
          tableTitle="Device History"
          // getMoreData={handleGetMoreData}
        />
      </Container>
    </Page>
  );
}

export default DeviceHistoryModule;
