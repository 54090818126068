import { GET_CONVERSION, GET_DASHBOARD, GET_WALLET, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  conversions: [],

  isLoading: false
};

export const ConversionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_CONVERSION: {
      return { ...state, conversions: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
