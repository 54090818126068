import { AdminsReducer } from './AdminReducer';
import { AdsReducer } from './AdsReducer';
import { ConversionReducer } from './ConversionReducer';
import { CustomersReducer } from './CustomersReducer';
import { DashboardReducer } from './DashboardReducer';
import { DeviceHistoryReducer } from './DeviceHistoryReducer';
import { DevicesReducer } from './DevicesReducer';
import { LogsReducer } from './LogsReducer';
import { PricePlanReducer } from './PricePlanReducer';
import { PriceReducer } from './PriceReducer';
import { ReportsReducer } from './ReportsReducer';

import { RoutesReducer } from './RoutesReducer';

import { TopupReducer } from './TopupReducer';
import { TransactionsReducer } from './TransactionsReducer';
import { VendorsReducer } from './VendorsReducer';
import { WalletReducer } from './WalletReducer';
import { WorldReducer } from './WorldReducer';

const { combineReducers } = require('redux');

const reducers = combineReducers({
  dashboard: DashboardReducer,
  Ads: AdsReducer,
  transactions: TransactionsReducer,
  topup: TopupReducer,
  logs: LogsReducer,
  vendors: VendorsReducer,
  wallet: WalletReducer,
  admins: AdminsReducer,
  prices: PriceReducer,
  routes: RoutesReducer,
  devices: DevicesReducer,
  reports: ReportsReducer,
  conversions: ConversionReducer,
  pricePlan: PricePlanReducer,
  world: WorldReducer,
  devicehistory: DeviceHistoryReducer,
  customers: CustomersReducer
});

export default reducers;
