import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import { Label } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import LOGLIST from '../../_mocks_/log.json';
import { getAdminLogsAction, getLogsAction } from '../../store/actions/LogsActions';
import { fDateTime, fDateTimeSuffix } from '../../utils/formatTime';
import FilterLog from './components/FilterLog';
import LogTabs from './LogTabs';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullname', label: 'Fullname', alignRight: false },
  { id: 'activityLogDesc', label: 'Action Description', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false }
  // { id: '', label: 'Actions' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.activityLogDesc.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

function CustomTable({ data, header, isLoading, _filterLog, loading, _page }) {
  //   const dispatch = useDispatch();
  // const { isLoading, ADMIN_LOG_LIST } = useSelector((state) => state.logs);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('fullname');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // dispatch(getAdminLogsAction());
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers?.length === 0;

  return (
    <>
      <FilterLog handleFilter={_filterLog} loading={loading} page={_page} />
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          {/* {isLoading ? <LinearProgress /> : null} */}
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={header}
              rowCount={data?.length}
              numSelected={selected?.length}
              onRequestSort={handleRequestSort}
              //   onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const { id, activityLogDesc, dateCreated } = row;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      //   selected={isItemSelected}
                      //   aria-checked={isItemSelected}
                    >
                      <TableCell>
                        {' '}
                        <Label />{' '}
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row?.admin?.fullname || row?.fullname || row?.vendor?.fullname}
                      </TableCell>
                      <TableCell align="left"> {activityLogDesc} </TableCell>

                      <TableCell align="left"> {fDateTime(dateCreated)} </TableCell>

                      {/* <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default CustomTable;
