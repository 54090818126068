import { toast } from 'react-toastify';
import { assignRoutesRequest, unassignRoutesRequest, getRoutesRequest } from 'src/api/RouteRequest';
import { LOAD_REQUEST, GET_TRANSACTIONS, LOAD_DONE, GET_ROUTES } from '../types';

export const getRoutesAction =
  (values = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOAD_REQUEST });

      const { data } = await getRoutesRequest(values);
      if (data?.error?.code === '1') {
        dispatch({ type: LOAD_DONE });
        return { error: 1, message: data?.error?.message };
      }
      // checkIfResponseOk(data);

      dispatch({ type: GET_ROUTES, payload: data.content.data.all });
      return data.content.data.all;
    } catch (err) {
      return { error: 1, message: err.message };
    }
  };

export const assignRoutesAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await assignRoutesRequest(payload);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);
    toast.success('Assigned Sucessfully!');
    dispatch(getRoutesAction());
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};

export const unassignRoutesAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await unassignRoutesRequest(payload);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);
    toast.success('Unassigned Sucessfully!');
    dispatch(getRoutesAction());
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};
