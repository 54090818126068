import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';
import { getVendorsAction } from '../../../store/actions/VendorsActions';
import { updateAdminPasswordAction } from '../../../store/actions/AdminActions';

function UpdatePassword({ open, handleClose }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.admins);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: ''
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required('Old Password is required'),
      newPassword: Yup.string().required('New Password is required')
    }),
    onSubmit: async (values) => {
      const response = await dispatch(updateAdminPasswordAction(values));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else handleClose();
    }
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              To change your password, please input your old and preferred new password.
            </DialogContentText>

            <TextField
              autoFocus
              margin="normal"
              id="oldPassword"
              label="Current Password"
              type="password"
              fullWidth
              value={values.oldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
            />

            <TextField
              margin="normal"
              id="newPassword"
              label="New Password"
              type="password"
              fullWidth
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
              Update Password
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default UpdatePassword;
