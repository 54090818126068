import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import { Label } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import TOPUPLIST from '../../_mocks_/topup.json';
import NewTopup from './components/NewTopup';
import { getTopupAction } from '../../store/actions/TopupActions';
import { fCurrency } from '../../utils/formatNumber';
import { fDate, fDateTime, fDateTimeSuffix, formatUTCDate, fToNow } from '../../utils/formatTime';
import FilterTopup from './components/FilterTopup';
import Table from '../../components/table';

function TopupModule() {
  const dispatch = useDispatch();
  const [filterLoader, setFilterLoader] = useState(false);
  const { isLoading, TOPUPLIST } = useSelector((state) => state.topup);

  const [openNewTopup, setOpenNewTopup] = useState(false);

  const toggleModal = () => {
    setOpenNewTopup(!openNewTopup);
  };

  useEffect(() => {
    dispatch(getTopupAction());
  }, []);

  const _filterTopup = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getTopupAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'fullname',
      label: 'Name',
      align: 'left',
      render: (index, rowData) => rowData?.vendor?.fullname
    },
    {
      id: 'requestedAmount',
      label: 'Requested Value',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData?.requestedAmount)
    },
    {
      id: 'amountPaid',
      label: 'Amount Paid',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData?.amountPaid)
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left',
      render: (index, rowData) => rowData?.status?.toUpperCase()
    },
    {
      id: 'channel',
      label: 'Channel',
      align: 'left'
    },
    {
      id: 'reference',
      label: 'Reference',
      align: 'left'
    },
    {
      id: 'timePaid',
      label: 'Date Paid',
      align: 'left',
      render: (index, rowData) => fDateTime(rowData?.timePaid)
    }
  ];

  return (
    <Page title="Topup Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Topup
          </Typography>
          <Button variant="contained" onClick={toggleModal} startIcon={<Icon icon={plusFill} />}>
            New Topup
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterTopup handleFilter={_filterTopup} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={TOPUPLIST}
          isLoading={isLoading}
          filterField="name"
          tableTitle="Topup"
          // getMoreData={handleGetMoreData}
        />

        {/* Modals */}
        <NewTopup open={openNewTopup} handleClose={toggleModal} />
      </Container>
    </Page>
  );
}

export default TopupModule;
