import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Container,
  DialogTitle,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Dialog
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Scrollbar from 'src/components/Scrollbar';
import { UserListToolbar } from 'src/components/_dashboard/user';
import { fDate } from 'src/utils/formatTime';
import CustomTable from 'src/components/table/CustomTable';
import { getDeviceViewsAction } from 'src/store/actions/DevicesActions';
import FilterDeviceViews from './FilterDeviceViews';
import { fNumber } from 'src/utils/formatNumber';

const DeviceViews = ({ open, handleClose, rowData, selectedModal, key }) => {
  const dispatch = useDispatch();
  const { isLoading, device_views } = useSelector((state) => state.devices);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterLoader, setFilterLoader] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (rowData?.id && selectedModal == "deviceViews") {
        dispatch(getDeviceViewsAction({ deviceID: rowData?.id }));
    }
  }, [rowData]);

  //   table head
  const TABLE_HEAD = [
    { id: 'serial', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'id',
      label: 'Device ID',
      align: 'left',
      render: (index, row) => <p>{row?.id || 'nil'}</p>
    },
    {
      id: 'busID',
      label: 'Bus ID',
      align: 'left',
      render: (index, row) => <p>{row?.busID || 'nil'}</p>
    },
    {
      id: 'views',
      label: 'Device Views',
      align: 'left',
      render: (index, row) => <p>{fNumber(row?.device?.views) || '0'}</p>
    },
    {
      id: 'clicks',
      label: 'Device Clicks',
      align: 'left',
      render: (index, row) => <p>{ fNumber(row?.device?.clicks) || '0'}</p>
    },
    {
      id: 'fromDate',
      label: 'From Date',
      align: 'left',
      render: (index, row) =>(row.fromDate ? fDate(row.fromDate) : null),
    },
    {
      id: 'toDate',
      label: 'To Date',
      align: 'left',
      render: (index, row) => (row.toDate ? fDate(row.toDate) : null),
    },
   
  ];

  const _filterDevices = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getDeviceViewsAction({ deviceID: rowData.id, ...values }));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  return (
    <div key={key}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'body'}
        fullWidth
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        style={{ overflow: 'auto' }}
      >
        <DialogTitle id="form-dialog-title"> Device {rowData?.name} Views</DialogTitle>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          style={{ padding: 20 }}
        >
          <FilterDeviceViews
            isDevice={rowData?.id}
            handleFilter={_filterDevices}
            loading={filterLoader}
          />
        </Stack>

        <Card style={{ overflow: 'auto' }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? <LinearProgress /> : null}
              <CustomTable
                DATALIST={device_views}
                TABLE_HEAD={TABLE_HEAD}
                selected={selected}
                filterName={filterName}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={device_views?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Dialog>
    </div>
  );
};

export default DeviceViews;