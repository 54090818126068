import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton, LocalizationProvider } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, IconButton, InputAdornment, Autocomplete } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getCitiesAction,
  getCountriesAction,
  getStatesAction
} from 'src/store/actions/WorldActions';

function FilterDeviceHistory({ handleFilter, loading }) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState({ country: [], state: [], city: [] });
  const { countries, states, cities } = useSelector((state) => state.world);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        serial: '',
        busID: '',
        from: '',
        to: '',
        countryID: '',
        countryLabel: '',
        stateID: '',
        stateLabel: '',
        cityID: '',
        cityLabel: '',
        deviceID: '',
        limit: ''
      },
      validationSchema: Yup.object().shape({
        //   vendorID: Yup.string().required('Vendor is required')
      }),
      onSubmit: (values) => {
        const fdate = moment(fromDate).unix();
        const tdate = moment(toDate).unix();
        handleFilter({ ...values, to: tdate, from: fdate });
      }
    });

  const handleClear = (e) => {
    e.stopPropagation();
    e?.target?.id == 'fromDate' ? setFromDate('') : setToDate('');
  };

  useEffect(() => {
    dispatch(getCountriesAction());
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      const countryOptions = countries.map((item) => ({ label: item.name, id: item.id }));
      const stateOptions = states.map((item) => ({ label: item.name, id: item.id }));
      const cityOptions = cities.map((item) => ({ label: item.name, id: item.id }));

      countryOptions.sort((a, b) => a.label.localeCompare(b.label));
      stateOptions.sort((a, b) => a.label.localeCompare(b.label));
      cityOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions((prev) => ({
        country: [...countryOptions],
        state: [...stateOptions],
        city: [...cityOptions]
      }));
    }
  }, [countries, states, cities]);

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="serial"
              label="Serial No."
              type="text"
              fullWidth
              value={values.serial}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.serial && errors.serial)}
              helperText={touched.serial && errors.serial}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="busID"
              label="Bus ID"
              type="text"
              fullWidth
              value={values.busID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.busID && errors.busID)}
              helperText={touched.busID && errors.busID}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="countryID"
              options={options.country}
              name="countryID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('countryID', newValue?.id || null);
                setFieldValue('countryLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getStatesAction({ countryID: newValue.id }));
                }
              }}
              fullWidth
              value={values.countryLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="Country" />}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="stateID"
              options={options.state}
              name="stateID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('stateID', newValue?.id || null);
                setFieldValue('stateLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getCitiesAction({ stateID: newValue.id }));
                }
              }}
              fullWidth
              value={values.stateLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="State" />}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="deviceID"
              label="Device ID"
              type="text"
              fullWidth
              value={values.deviceID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.deviceID && errors.deviceID)}
              helperText={touched.deviceID && errors.deviceID}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              margin="normal"
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onBlur={handleBlur}
              error={Boolean(touched.from && errors.from)}
              helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              //   value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onBlur={handleBlur}
              error={Boolean(touched.to && errors.to)}
              helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="limit"
              label="Count"
              type="text"
              fullWidth
              value={values.limit}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.limit && errors.limit)}
              helperText={touched.limit && errors.limit}
            />
          </Grid>

          <Grid sm={3}>
            <LoadingButton
              style={{ marginTop: 40, marginLeft: 20 }}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterDeviceHistory;
