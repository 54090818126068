import {
  createPricePlanRequest,
  disablePricePlanRequest,
  getPricePlanRequest,
  updatePricePlanRequest
} from 'src/api/PricePlanRequest';
import { EMPTY_LIST, GET_PRICE_PLAN, LOAD_DONE, LOAD_REQUEST } from '../types';
import { toast } from 'react-toastify';

export const getPricePlanAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getPricePlanRequest(values);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_PRICE_PLAN, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createPricePlanAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    if (obj.vendorID == '') {
      obj.vendorID = null;
    }
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await createPricePlanRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!');
    dispatch(getPricePlanAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updatePricePlanAction = (obj, id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await updatePricePlanRequest(id, reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Updated Sucessfully!');
    dispatch(getPricePlanAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const disablePricePlanAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await disablePricePlanRequest(id);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Disabled Sucessfully!');
    dispatch(getPricePlanAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
