import axios from './index';

export const getPricePlanRequest = (values) =>
  axios.get(
    `/v1/admins/price-plans/get/price-plans/-/${values?.vendorID || '-'}/${values?.status || '-'}/${
      values?.from || '-'
    }/${values?.to || '-'}/-/-`
  );

export const createPricePlanRequest = (reqObj) =>
  axios.post('/v1/admins/price-plans/create/price-plan', reqObj);

export const updatePricePlanRequest = (planID, reqObj) =>
  axios.put(`/v1/admins/price-plans/update/price-plan/${planID}`, reqObj);

export const disablePricePlanRequest = (planID) =>
  axios.put(`/v1/admins/price-plans/disable/price-plan/${planID}`);
