import axios from './index';

export const getCustomersRequest = ({
  id = '-',
  vendorID = '-',
  phone = '-',
  ageRange = '-',
  gender = '-',
  page = '-',
  limit = '-'
}) =>
  axios.get(
    `/v1/admins/get/customers/${id || '-'}/${vendorID || '-'}/${phone || '-'}/${ageRange || '-'}/${
      gender || '-'
    }/${page || '-'}/${limit || '-'}`
  );
