import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Card,
  Stack,
  Grid
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletAction } from '../../../store/actions/WalletActions';
import { fCurrency } from 'src/utils/formatNumber';
function VendorDetails({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { WALLET_LIST: wallet, isLoading } = useSelector((state) => state.wallet);

  useEffect(() => {
    if (rowData) {
      const { vendorID } = rowData;
      dispatch(getWalletAction({ vendorID }));
    }
  }, [rowData]);
  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="vendor_details">
        <DialogTitle id="vendor_details">Vendor Details</DialogTitle>

        <DialogContent>
          {isLoading && <CircularProgress size={3} color="primary" />}
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card style={{ backgroundColor: '#769FB6' }}>
                  <Stack direction="column" spacing={1} padding={4}>
                    <h2>{fCurrency(wallet[0]?.amount || 0)}</h2>
                    <p>Wallet Balance</p>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card style={{ backgroundColor: '#769FB6' }}>
                  <Stack direction="row" justifyContent="space-between" spacing={1} padding={2}>
                    <div>
                      <h4>{wallet[0]?.vendor?.fullname || '-'}</h4>
                      <p style={{ fontSize: '.9em' }}>Fullname</p>
                    </div>
                    <div>
                      <h4>{wallet[0]?.vendor?.email || '-'}</h4>
                      <p style={{ fontSize: '.9em' }}>Email</p>
                    </div>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" spacing={1} padding={2}>
                    <div>
                      <h4>{wallet[0]?.vendor?.username || '-'}</h4>
                      <p style={{ fontSize: '.9em' }}>Username</p>
                    </div>
                    <div>
                      <h4>
                        {wallet[0]?.vendor?.accessStatus === 1 ? 'Active' : 'Not active' || '-'}
                      </h4>
                      <p style={{ fontSize: '.9em' }}>Status</p>
                    </div>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VendorDetails;
