import {
  DEVICE_VIEWS_EMPTY,
  DEVICES_EMPTY,
  EMPTY_LIST,
  GET_DEVICE_VIEWS,
  GET_DEVICES,
  LOAD_DONE,
  LOAD_REQUEST
} from '../types';

const initialState = {
  DEVICES_LIST: [],
  device_views: [],
  isLoading: false
};

export const DevicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_DEVICES: {
      return { ...state, DEVICES_LIST: payload, isLoading: false };
    }
    case GET_DEVICE_VIEWS: {
      return {
        ...state,
        device_views: payload,
        isLoading: false
      };
    }
    case DEVICE_VIEWS_EMPTY: {
      return { ...state, device_views: [], isLoading: false };
    }
    case DEVICES_EMPTY: {
      return { ...state, isLoading: false, DEVICES_LIST: [] };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
