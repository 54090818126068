import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import { Label } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AdminProfile from './components/AdminProfile';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import ADMIN_LIST from '../../_mocks_/admin.json';
import NewAdmin from './components/NewAdmin';
import UpdateAdmin from './components/UpdateAdmin';
import { getTopupAction } from '../../store/actions/TopupActions';
import { fCurrency } from '../../utils/formatNumber';
import { fDate, fDateTime, fDateTimeSuffix } from '../../utils/formatTime';
import MoreMenu from '../../components/MoreMenu';
import {
  getAdminsAction,
  getCurrentAdminAction,
  logoutAdminAction,
  resetAdminPasswordAction
} from '../../store/actions/AdminActions';

import ViewPasswordInfo from '../VendorModule/components/ViewPasswordResetInfo';
import UpdatePassword from './components/UpdatePassword';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullname', label: 'Fullname', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'usertype', label: 'Role', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: '' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function AdminModule() {
  const dispatch = useDispatch();
  const { isLoading, ADMIN_LIST } = useSelector((state) => state.admins);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('fullname');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openNewAdmin, setOpenNewAdmin] = useState(false);

  const [currentRow, setCurrentRow] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [showPwdInfo, setShowPwdInfo] = useState(false);

  const [isUpdatePwdModalOpen, setIsUpdatePwdModalOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ADMIN_LIST.length) : 0;

  const filteredUsers = applySortFilter(ADMIN_LIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const toggleModal = () => {
    setOpenNewAdmin(!openNewAdmin);
  };

  useEffect(() => {
    dispatch(getAdminsAction());
    dispatch(getCurrentAdminAction());
  }, []);

  const handleViewUpdateModal = (row) => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
    setCurrentRow(row);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleShowPwdInfo = (message) => {
    setShowPwdInfo(!showPwdInfo);
    setCurrentRow(message);
  };

  const handleClosePwdInfoModal = () => {
    setShowPwdInfo(false);
  };

  const handleUpdatePasswordModal = () => {
    setIsUpdatePwdModalOpen(!isUpdatePwdModalOpen);
  };

  const handleCloseUpdatePwdModal = () => {
    setIsUpdatePwdModalOpen(false);
  };

  const handleResetAdminPassword = async (id) => {
    const action = window.confirm(`Are you sure you want to Reset this Admin Password`);
    if (action) {
      const response = await dispatch(resetAdminPasswordAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else handleShowPwdInfo(response);
    }
  };

  const handleLogoutAdmin = async (id) => {
    const action = window.confirm(`Are you sure you want to Logout this Admin`);
    if (action) {
      const response = await dispatch(logoutAdminAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      }
    }
  };

  return (
    <Page title="Admin Profile  ">
      <Container>
        <AdminProfile handleUpdateAdminPwd={handleUpdatePasswordModal} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
          <Typography variant="h4" gutterBottom>
            Admin Profile
          </Typography>
          <Button variant="contained" onClick={toggleModal} startIcon={<Icon icon={plusFill} />}>
            New Admin
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? <LinearProgress /> : null}
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={ADMIN_LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //   onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        fullname,
                        username,
                        phone,
                        email,
                        address,
                        usertype,
                        dateCreated
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell>
                            <Label />
                          </TableCell>
                          <TableCell>00{id}</TableCell>
                          <TableCell>{fullname}</TableCell>
                          <TableCell align="left"> {username} </TableCell>

                          <TableCell align="left"> {phone} </TableCell>

                          <TableCell align="left"> {email} </TableCell>

                          <TableCell align="left"> {address} </TableCell>

                          <TableCell align="left">
                            {' '}
                            {usertype === 0 ? 'Admin' : 'Non-Admin'}{' '}
                          </TableCell>

                          <TableCell align="left">{fDate(dateCreated)}</TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              edit
                              reset
                              logout
                              handleLogout={() => handleLogoutAdmin(row.id)}
                              handleEdit={() => handleViewUpdateModal(row)}
                              handleReset={() => handleResetAdminPassword(row.id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={ADMIN_LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {/* Modals */}
        <NewAdmin open={openNewAdmin} handleClose={toggleModal} />
        <UpdateAdmin
          open={isUpdateModalOpen}
          handleClose={handleCloseUpdateModal}
          rowData={currentRow}
        />
        <ViewPasswordInfo
          open={showPwdInfo}
          handleClose={handleClosePwdInfoModal}
          messageObj={currentRow}
        />
        <UpdatePassword open={isUpdatePwdModalOpen} handleClose={handleCloseUpdatePwdModal} />
      </Container>
    </Page>
  );
}

export default AdminModule;
