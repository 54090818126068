import axios from './index';

export const getCountriesRequest = (values) =>
  axios.get(
    `/v1/admins/world/get/countries/-/${values?.stateID || '-'}/${values?.cityID || '-'}/-/-`
  );

export const getStateRequest = (values) =>
  axios.get(
    `/v1/admins/world/get/states/${values?.countryID || '-'}/-/${values?.cityID || '-'}/-/-`
  );

export const getCitiesRequest = (values) =>
  axios.get(
    `/v1/admins/world/get/cities/${values?.countriesID || '-'}/${values?.stateID || '-'}/-/-/-`
  );
