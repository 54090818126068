import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Icon,
  Chip
} from '@material-ui/core';
import { LaunchOutlined, EditOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import plusFill from '@iconify/icons-eva/plus-fill';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListToolbar } from '../../components/_dashboard/user';
import { fDate } from '../../utils/formatTime';
// import DeviceDetails from './components/DeviceDetails';
import CustomTable from '../../components/table/CustomTable';
import { getDevicesAction } from 'src/store/actions/DevicesActions';
import CreateDevice from './components/CreateDevice';
import UpdateDevice from './components/UpdateDevice';
import { getReportsAction } from 'src/store/actions/ReportsActions';
import moment from 'moment';
import FilterDevices from './components/FilterDevices';
import AssignDevices from './components/AssignDevice';
import DeviceHistory from './components/DeviceHistory';
import Table from '../../components/table';
import { set } from 'lodash';
import DeviceStatus from './components/DeviceStatus';
import DeviceViews from './components/DeviceViews';
import { fNumber } from 'src/utils/formatNumber';
import { DEVICE_HISTORY_EMPTY, DEVICE_VIEWS_EMPTY } from 'src/store/types';
// import FilterTopup from './components/FilterTopup';

export const initialDeviceCache = {
  serial: '',
  busID: '',
  from: moment().subtract(1, 'd').unix() || '',
  to: moment().unix() || '',
  countryID: '',
  countryLabel: '',
  stateID: '',
  stateLabel: '',
  cityID: '',
  cityLabel: '',
  limit: '25',
  page: 1,
  viewCountFrom: moment().subtract(1, 'd').unix() || '',
  viewCountTo: moment().unix() || '',
  activeStatus: 1
};

// TODO: Rewrite this component.
/***
 * Modals should share a single state
 * one reset function should reset all modals
 * and generally just separate concerns better
 */
function DevicesModule() {
  const dispatch = useDispatch();
  const { isLoading, DEVICES_LIST } = useSelector((state) => state.devices);
  const [filterLoader, setFilterLoader] = useState(false);
  const [selected, setSelected] = useState('');

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewHistory, setOpenViewHistory] = useState(false);
  const [openDeviceViews, setOpenDeviceViews] = useState(false);
  const [openDeviceStatus, setOpenDeviceStatus] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [createDeviceModal, setCreateDeviceModal] = useState(false);
  const [devicesCacheRequest, setDevicesCacheRequest] = useState({
    serial: '',
    busID: '',
    from: moment().subtract(1, 'd').unix() || '',
    to: moment().unix() || '',
    countryID: '',
    countryLabel: '',
    stateID: '',
    stateLabel: '',
    cityID: '',
    cityLabel: '',
    limit: '25',
    page: 1,
    viewCountFrom: moment().subtract(1, 'd').unix() || '',
    viewCountTo: moment().unix() || '',
    activeStatus: 1,
    totalViews: 0,
    totalClicks: 0
  });

  const toggleCreateModal = () => {
    setCreateDeviceModal(!createDeviceModal);
  };

  useEffect(() => {
    dispatch(getDevicesAction(devicesCacheRequest));
  }, []);

  const toggleUpdateModal = (rowData) => {
    if (rowData) setRowData(rowData);
    setOpenUpdateModal(!openUpdateModal);
  };

  const toggleAssignModal = (rowData) => {
    if (rowData) setRowData(rowData);
    setOpenAssignModal(!openAssignModal);
  };

  const toggleViewHistory = (rowData) => {
    if (rowData) {
      setSelected('deviceHistory');
      setRowData(rowData);
    }
    setOpenViewHistory(!openViewHistory);
  };

  const toggleDeviceStatus = (rowData) => {
    if (rowData) {
      setRowData(rowData);
    }
    setOpenDeviceStatus(!openDeviceStatus);
  };

  const toggleDeviceViews = (rowData) => {
    if (rowData) {
      setRowData(rowData);
      setSelected('deviceViews');
    }
    setOpenDeviceViews(!openDeviceViews);
  };

  const resetModals = () => {
    setOpenViewHistory(false);
    setOpenDeviceViews(false);
    dispatch({ type: DEVICE_HISTORY_EMPTY });
    dispatch({ type: DEVICE_VIEWS_EMPTY });
    setSelected('');
  };

  //   table head
  const TABLE_HEAD = [
    {
      id: 'id',
      label: 'S/N',
      render: (index, row) => {
        return index + 1;
      }
    },
    { id: 'name', label: 'Device Name', align: 'left' },
    { id: 'serial', label: 'Serial Number', align: 'left' },
    { id: 'busID', label: 'Bus ID', align: 'left' },
    { id: 'phone', label: 'Phone Number', align: 'left' },
    {
      id: 'activeStatus',
      label: 'Status',
      align: 'left',
      render: (index, row) => (
        <Chip
          label={row.activeStatus === 1 ? 'Active' : 'Inactive'}
          color={row.activeStatus === 1 ? 'success' : 'error'}
        />
      )
    },
    // {
    //   id: 'countries',
    //   label: 'Country',
    //   align: 'left',
    //   render: (index, row) => (
    //     <p>
    //       {row.countries.map((item, index) => {
    //         return <p> {item.name} </p>;
    //       })}
    //     </p>
    //   )
    // },
    {
      id: 'states',
      label: 'State',
      align: 'left',
      render: (index, row) => (
        <p>
          {row.states.map((item, index) => {
            return <p> {item.name} </p>;
          })}
        </p>
      )
    },
    {
      id: 'totalViews',
      label: 'Total Views',
      align: 'left',
      render: (index, row) => fNumber(row.totalViews)
    },
    {
      id: 'totalClicks',
      label: 'Total Clicks',
      align: 'left',
      render: (index, row) => fNumber(row.Clicks)
    },
    {
      id: 'lastseen',
      label: 'Last Seen',
      align: 'left',
      render: (index, row) =>
        row.lastseen ? (
          moment(row.lastseen * 1000).format('Do MMMM YYYY h:mm a')
        ) : (
          <Chip label="Inactive" color="info" />
        )
    },
    {
      id: 'routes',
      label: 'Route Name',
      align: 'left',
      render: (index, rowData) => {
        // gets the last route the bus was on
        if (rowData.routes?.length > 0) {
          let idx = rowData.routes.length - 1;
          const route = rowData.routes[idx];
          return <> {route.name} </>;
        } else return 'None';
      }
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      render: (index, rowData) => (rowData.dateCreated ? fDate(rowData.dateCreated) : null),
      align: 'left'
    },
    {
      id: 'actions',
      label: 'Actions',
      render: (index, rowData) => (
        <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
          <Button onClick={() => toggleUpdateModal(rowData)}>
            <EditOutlined />
          </Button>
          <Button
            variant="outlined"
            onClick={() => toggleDeviceStatus(rowData)}
            style={{ fontSize: 10 }}
          >
            {rowData.activeStatus === 1 ? 'Disable Device' : 'Enable Device'}
          </Button>
          <Button
            variant="contained"
            onClick={() => toggleAssignModal(rowData)}
            style={{ fontSize: 10 }}
          >
            Assign Devices
          </Button>
          <Button
            variant="contained"
            onClick={() => toggleViewHistory(rowData)}
            style={{ fontSize: 10 }}
          >
            View History
          </Button>
          <Button
            variant="contained"
            onClick={() => toggleDeviceViews(rowData)}
            style={{ fontSize: 10 }}
          >
            Device Views
          </Button>
        </Stack>
      ),
      align: 'left'
    }
  ];

  const _filterDevices = async (values) => {
    setFilterLoader(true);
    setDevicesCacheRequest(values);
    const response = await dispatch(getDevicesAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  const handleGetMoreData = async () => {
    const { page, limit } = devicesCacheRequest;
    const nextPage = page + 1;
    const values = { ...devicesCacheRequest, page: nextPage, limit };
    const response = await dispatch(getDevicesAction(values, 'getMore'));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
  };

  return (
    <Page title="Device Module">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Devices
          </Typography>
          <Button
            variant="contained"
            onClick={toggleCreateModal}
            startIcon={<Icon icon={plusFill} />}
          >
            New Device
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterDevices handleFilter={_filterDevices} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={DEVICES_LIST}
          isLoading={isLoading}
          filterField="serial"
          searchPlaceholder="Search by Serial Number"
          tableTitle="Devices"
          getMoreData={handleGetMoreData}
        />

        {/* Modals */}
        <DeviceStatus open={openDeviceStatus} handleClose={toggleDeviceStatus} rowData={rowData} />
        <CreateDevice open={createDeviceModal} handleClose={toggleCreateModal} />
        <UpdateDevice open={openUpdateModal} handleClose={toggleUpdateModal} rowData={rowData} />
        <AssignDevices open={openAssignModal} handleClose={toggleAssignModal} rowData={rowData} />
        <DeviceHistory
          open={openViewHistory}
          handleClose={resetModals}
          rowData={rowData}
          key={rowData?.id}
          selectedModal={selected}
        />
        <DeviceViews
          open={openDeviceViews}
          handleClose={resetModals}
          rowData={rowData}
          selectedModal={selected}
          key={rowData?.id}
        />
      </Container>
    </Page>
  );
}

export default DevicesModule;
