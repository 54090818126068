import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Container,
  DialogTitle,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Dialog
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Scrollbar from 'src/components/Scrollbar';
import { UserListToolbar } from 'src/components/_dashboard/user';
import { fDate } from 'src/utils/formatTime';
import CustomTable from 'src/components/table/CustomTable';
import moment from 'moment';
import FilterDevices from '../components/FilterDevices';
import { getDeviceHistoryAction } from 'src/store/actions/DeviceHistoryActions';

const DeviceHistory = ({ open, handleClose, rowData, key, selectedModal }) => {
  const dispatch = useDispatch();
  const { isLoading, DEVICE_HISTORY } = useSelector((state) => state.devicehistory);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterLoader, setFilterLoader] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (rowData?.id && selectedModal == 'deviceHistory') {
      dispatch(getDeviceHistoryAction({ deviceID: rowData?.id }));
    }
  }, [rowData]);

  //   table head
  const TABLE_HEAD = [
    { id: 'serial', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'name',
      label: 'Device Name',
      align: 'left',
      render: (index, row) => <p>{row?.device?.name || 'nil'}</p>
    },
    {
      id: 'serial',
      label: 'Serial Number',
      align: 'left',
      render: (index, row) => <p>{row?.device?.serial || 'nil'}</p>
    },
    {
      id: 'busID',
      label: 'Bus ID',
      align: 'left',
      render: (index, row) => <p>{row?.busID || 'nil'}</p>
    },
    {
      id: 'lastseen',
      label: 'Last Seen',
      align: 'left',
      render: (index, row) =>
        row?.lastseen ? (
          moment(row?.device?.lastseen * 1000).format('Do MMMM YYYY h:mm a')
        ) : (
          <Chip label="Inactive" color="info" />
        )
    },
    {
      id: 'views',
      label: 'Device Views',
      align: 'left',
      render: (index, row) => <p>{row?.device?.views || 'nil'}</p>
    },
    {
      id: 'routename',
      label: 'Route Name',
      align: 'left',
      render: (index, row) => <p>{row?.route?.name || 'nil'}</p>
    },
    {
      id: 'countries',
      label: 'Country',
      align: 'left',
      render: (index, row) => (
        <p>
          {row?.countries?.map((item, index) => {
            return <p> {item?.name || 'nil'} </p>;
          })}
        </p>
      )
    },
    {
      id: 'states',
      label: 'State',
      align: 'left',
      render: (index, row) => (
        <p>
          {row?.states?.map((item, index) => {
            return <p> {item?.name || 'nil'} </p>;
          })}
        </p>
      )
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      render: (index, rowData) => (rowData.dateCreated ? fDate(rowData.dateCreated) : null),
      align: 'left'
    },
    {
      id: 'dateUpdated',
      label: 'Date Updated',
      render: (index, rowData) => (rowData.dateUpdated ? fDate(rowData.dateUpdated) : null),
      align: 'left'
    }
  ];

  const _filterDevices = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getDeviceHistoryAction({ deviceID: rowData.id, ...values }));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  return (
    <div key={key}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'body'}
        fullWidth
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        style={{ overflow: 'auto' }}
      >
        <DialogTitle id="form-dialog-title"> Device History</DialogTitle>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          style={{ padding: 20 }}
        >
          <FilterDevices
            isDevice={rowData?.id}
            handleFilter={_filterDevices}
            loading={filterLoader}
          />
        </Stack>

        <Card style={{ overflow: 'auto' }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? <LinearProgress /> : null}
              <CustomTable
                DATALIST={DEVICE_HISTORY}
                TABLE_HEAD={TABLE_HEAD}
                selected={selected}
                filterName={filterName}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={DEVICE_HISTORY?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Dialog>
    </div>
  );
};

export default DeviceHistory;
