import { removeDupsFromArray } from 'src/utils/utilities';
import { GET_CUSTOMERS, GET_CUSTOMERS_EMPTY, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  customers: [],
  isLoading: false
};

export const CustomersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_CUSTOMERS: {
      return {
        ...state,
        customers: removeDupsFromArray([...state.customers, ...payload]),
        isLoading: false
      };
    }

    case GET_CUSTOMERS_EMPTY: {
      return { ...state, isLoading: false, customers: [] };
    }

    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
