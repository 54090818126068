import axios from './index';


export const getLogsRequest = (values) =>
  axios.get(`/v1/admins/get/self/activity-logs/${values?.from || '-'}/${values?.to || '-'}`);

export const getAdminLogsRequest = (values) =>
  axios.get(
    `/v1/admins/get/activity-logs/${values?.adminID || '-'}/${values?.from || '-'}/${
      values?.to || '-'
    }`
  );

export const getVendorLogsRequest = (values) =>
  axios.get(
    `/v1/admins/get/vendor/activity-logs/${values?.userID || '-'}/${values?.from || '-'}/${
      values?.to || '-'
    }`
  );

