import { getDayBefore, getTodayDate } from 'src/utils/utilities';
import axios from './index';

export const getDeviceHistoryRequest = (values) =>
  axios.get(
    `/v1/admins/devices/get/device-history/-/${values?.busID || '-'}/${values?.deviceID || '-'}/${
      values?.countryID || '-'
    }/${values?.stateID || '-'}/${values?.cityID || '-'}/${values?.routeID || '-'}/${
      values?.from || getDayBefore()
    }/${values?.to || getTodayDate()}/${values?.page || '-'}/${values?.limit || '20'}`
  );
