import React, { useEffect } from 'react';
import { TextField, MenuItem, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { sortArrayBy } from 'src/utils/utilities';

function FilterCustomers({ handleFilter, loading }) {
  const dispatch = useDispatch();
  const { VENDORS_LIST } = useSelector((state) => state.vendors);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        vendorID: '',
        phone: '',
        ageRange: '',
        gender: ''
      },
      onSubmit: (values) => {
        handleFilter({ ...values });
      }
    });

  useEffect(() => {
    dispatch(getVendorsAction({}));
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.vendorID && errors.vendorID)}
              helperText={touched.vendorID && errors.vendorID}
              fullWidth
              select
              margin="normal"
              name="vendorID"
              label="Vendor"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.vendorID}
            >
              <MenuItem value="">Please select Vendor</MenuItem>
              {Boolean(VENDORS_LIST) &&
                sortArrayBy(VENDORS_LIST, 'fullname').map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.fullname}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="phone"
              label="Phone No."
              type="text"
              fullWidth
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="ageRange"
              label="Age Range"
              type="text"
              fullWidth
              value={values.ageRange}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.ageRange && errors.ageRange)}
              helperText={touched.ageRange && errors.ageRange}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.gender && errors.gender)}
              helperText={touched.gender && errors.gender}
              fullWidth
              select
              margin="normal"
              name="gender"
              label="Gender"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.gender}
            >
              <MenuItem value="">Please select Gender</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>

          <Grid sm={3}>
            <LoadingButton
              style={{ marginTop: 40, marginLeft: 20 }}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default FilterCustomers;
