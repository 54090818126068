import axios from './index';

export const getAdminsRequest = () => axios.get('/v1/admins/get/admins');

export const getAdminRequest = () => axios.get('/v1/admins/get/admin');

export const createAdminRequest = (reqObj) => axios.post('/v1/admins/create/admin', reqObj);

export const updateAdminRequest = (adminID, reqObj) =>
  axios.put(`/v1/admins/update/admin/${adminID}`, reqObj);

export const resetAdminPasswordRequest = (adminID) =>
  axios.put(`/v1/admins/reset/admin/password/${adminID}`);

export const updateAdminPasswordRequest = (reqObj) =>
  axios.put(`/v1/admins/update/admin/password`, reqObj);

export const logoutAdminRequest = (adminID) => axios.post(`/v1/admins/logout/admin/${adminID}`);
