import { EMPTY_LIST, GET_PRICE_PLAN, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  pricePlan: [],
  isLoading: false
};

export const PricePlanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_PRICE_PLAN: {
      return { ...state, pricePlan: payload, isLoading: false };
    }
    case EMPTY_LIST: {
      return { ...state, isLoading: false, pricePlan: [] };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
