import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
// import googleAds from '@iconify-icons/mdi/google-ads';
// import { Icon, InlineIcon } from '@iconify/react';
import monitorFill from '@iconify-icons/eva/monitor-fill';
import briefcaseFill from '@iconify-icons/eva/briefcase-fill';
import creditCardFill from '@iconify-icons/eva/credit-card-fill';
import creditCardOutline from '@iconify-icons/eva/credit-card-outline';
import layersFill from '@iconify-icons/eva/layers-fill';

// ----------------------------------------------------------------------
// privileges
// ADMIN = "ADMIN_ADMIN"
// VENDOR = "ADMIN_VENDOR"
// ACTIVITY_LOG = "ADMIN_ACTIVITY_LOG"
// TOPUP = "ADMIN_TOPUP"
// WALLET = "ADMIN_WALLET"
// AD = "ADMIN_AD"
// ROUTE = "ADMIN_ROUTE"
// PRICE = "ADMIN_PRICE"
// TRANSACTION = "ADMIN_TRANSACTION"
// REPORT = "ADMIN_REPORT"
// CUSTOMER = "ADMIN_CUSTOMER"
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    module: 'Dashboard'
  },
  {
    title: 'ads',
    path: '/dashboard/ads',
    icon: getIcon(monitorFill),
    module: 'ADMIN_AD'
  },
  // {
  //   title: 'conversions',
  //   path: '/dashboard/conversions',
  //   icon: getIcon(monitorFill),
  //   module: 'ADMIN_AD'
  // },
  {
    title: 'devices',
    path: '/dashboard/devices',
    icon: getIcon(monitorFill),
    module: 'ADMIN_DEVICE'
  },
  {
    title: 'device-history',
    path: '/dashboard/device-history',
    icon: getIcon(monitorFill),
    module: 'ADMIN_DEVICE'
  },
  {
    title: 'vendor',
    path: '/dashboard/vendors',
    icon: getIcon(peopleFill),
    module: 'ADMIN_VENDOR'
  },
  {
    title: 'wallet',
    path: '/dashboard/wallet',
    icon: getIcon(creditCardOutline),
    module: 'ADMIN_WALLET'
  },

  {
    title: 'topup',
    path: '/dashboard/topup',
    icon: getIcon(briefcaseFill),
    module: 'ADMIN_TOPUP'
  },
  {
    title: 'routes',
    path: '/dashboard/routes',
    icon: getIcon(briefcaseFill),
    module: 'ADMIN_ROUTE'
  },

  {
    title: 'price (Deprecated)',
    path: '/dashboard/price',
    icon: getIcon(creditCardFill),
    module: 'ADMIN_PRICE'
  },

  {
    title: 'price plan',
    path: '/dashboard/pplan',
    icon: getIcon(creditCardFill),
    module: 'ADMIN_PRICE'
  },
  {
    title: 'price',
    path: '/dashboard/price',
    icon: getIcon(creditCardFill)
  },
  {
    title: 'customers',
    path: '/dashboard/customers',
    icon: getIcon(creditCardFill),
    module: 'ADMIN_CUSTOMER'
  },
  {
    title: 'summary',
    path: '/dashboard/transactions',
    icon: getIcon(creditCardFill),
    module: 'ADMIN_TRANSACTION'
  },
  {
    title: 'reports',
    path: '/dashboard/reports',
    icon: getIcon(peopleFill),
    module: 'ADMIN_REPORT'
  },
  {
    title: 'activity log',
    path: '/dashboard/activitylog',
    icon: getIcon(layersFill),
    module: 'ADMIN_ACTIVITY_LOG'
  },
  {
    title: 'admin',
    path: '/dashboard/admin',
    icon: getIcon(layersFill),
    module: 'ADMIN_ADMIN'
  }
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // }
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
