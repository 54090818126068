import { GET_ADMINS, GET_CURRENT_ADMIN, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  ADMIN_LIST: [],
  currentAdmin: null,
  isLoading: false
};

export const AdminsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_ADMINS: {
      return { ...state, ADMIN_LIST: payload, isLoading: false };
    }
    case GET_CURRENT_ADMIN: {
      return { ...state, currentAdmin: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};
