import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { searchVendorsAction } from '../../../store/actions/VendorsActions';
import { createPricePlanAction } from 'src/store/actions/PricePlanActions';

function CreatePlan({ open, handleClose }) {
  const dispatch = useDispatch();
  const [vendorsOptions, setVendorOptions] = useState([]);
  const [vendorInputValue, setVendorInputValue] = useState('');
  const { VENDORS_LIST } = useSelector((state) => state.vendors);
  const [loading, setLoading] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState('');

  useEffect(() => {
    // dispatch(getVendorsAction());
  }, []);

  useEffect(() => {
    if (VENDORS_LIST.length > 0) {
      const options = VENDORS_LIST.map((item) => ({ label: item.fullname, id: item.id }));
      setVendorOptions(options);
    }
  }, [VENDORS_LIST]);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue
  } = useFormik({
    initialValues: {
      name: '',
      vendorID: null,
      onViewPrice: '',
      onClickPrice: '',
      onConversionPrice: '',
      vendorLabel: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      vendorID: Yup.string().nullable(),
      onViewPrice: Yup.string().required('Required'),
      onClickPrice: Yup.string().required('Required'),
      onConversionPrice: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const response = await dispatch(createPricePlanAction(values));
      setLoading(false);
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      } else handleClose();
    }
  });

  useEffect(() => {
    if (vendorInputValue.trim().length > 0) {
      if (debounceTimer) clearTimeout(debounceTimer);
      let timer = setTimeout(() => {
        dispatch(searchVendorsAction(vendorInputValue));
      }, [700]);

      setDebounceTimer(timer);
    }
  }, [vendorInputValue]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Price Plan</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>Set a price plan for the Vendor Ads</DialogContentText>
            <Autocomplete
              disablePortal
              id="vendorID"
              options={vendorsOptions}
              sx={{ width: 300 }}
              name="vendorID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('vendorID', newValue?.id || null);
                setFieldValue('vendorLabel', newValue?.label);
              }}
              inputValue={vendorInputValue}
              onInputChange={(event, newInputValue) => {
                setVendorInputValue(newInputValue);
              }}
              fullWidth
              value={values.vendorLabel}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Search Vendor" />
              )}
            />

            {/* <TextField
              error={Boolean(touched.vendorID && errors.vendorID)}
              helperText={touched.vendorID && errors.vendorID}
              fullWidth
              select
              margin="normal"
              name="vendorID"
              label="Vendor"
              required
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.vendorID}
            >
              <MenuItem value="">Please select Vendor</MenuItem>
              <MenuItem value="null">All Vendors</MenuItem>
              {VENDORS_LIST.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.fullname}
                </MenuItem>
              ))}
            </TextField> */}

            <TextField
              margin="normal"
              id="name"
              label="name"
              type="text"
              fullWidth
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              margin="normal"
              id="onViewPrice"
              label="Price on Ad View"
              type="number"
              fullWidth
              value={values.onViewPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.onViewPrice && errors.onViewPrice)}
              helperText={touched.onViewPrice && errors.onViewPrice}
            />
            <TextField
              margin="normal"
              id="onClickPrice"
              label="Price on Ad Click"
              type="number"
              fullWidth
              value={values.onClickPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.onClickPrice && errors.onClickPrice)}
              helperText={touched.onClickPrice && errors.onClickPrice}
            />
            <TextField
              margin="normal"
              id="onConversionPrice"
              label="Price on Ad Conversion"
              type="number"
              fullWidth
              value={values.onConversionPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.onConversionPrice && errors.onConversionPrice)}
              helperText={touched.onConversionPrice && errors.onConversionPrice}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Create Price Plan
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default CreatePlan;
