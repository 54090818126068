import { getConversionRequest } from 'src/api/ConversionRequest';
import { GET_CONVERSION, LOAD_DONE, LOAD_REQUEST } from '../types';

export const getConversionAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getConversionRequest(values);

    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_CONVERSION, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
