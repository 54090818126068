import axios from './index';

export const getRoutesRequest = (values) =>
  axios.get(
    `/v1/admins/get/routes/-/${values?.page || '1'}/${values?.limit || '-'}/${
      values?.countryID || '-'
    }/${values?.stateID || '-'}/${values?.cityID || '-'}/${values?.busID || '-'}/${
      values?.deviceID || '-'
    }`
  );

export const assignRoutesRequest = (payload) =>
  axios.post(`/v1/admins/routes/route/assign/places`, payload);

export const unassignRoutesRequest = (payload) =>
  axios.post(`/v1/admins/routes/route/unassign/places`, payload);
