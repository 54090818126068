import {
  DEVICE_HISTORY_EMPTY,
  EMPTY_LIST,
  GET_DEVICE_HISTORY,
  LOAD_DONE,
  LOAD_REQUEST
} from '../types';

const initialState = {
  DEVICE_HISTORY: [],
  isLoading: false
};

export const DeviceHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_DEVICE_HISTORY: {
      return { ...state, DEVICE_HISTORY: payload, isLoading: false };
    }

    case DEVICE_HISTORY_EMPTY: {
      return { ...state, isLoading: false, DEVICE_HISTORY: [] };
    }

    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
