import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import { Label } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import WALLET_LIST from '../../_mocks_/wallet.json';
import { getTransactonAction } from '../../store/actions/TransactionActions';
import { fDateTime } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import { getWalletAction } from '../../store/actions/WalletActions';
import FilterAds from '../AdsModule/components/FilterAds';
import Table from '../../components/table';
import FilterWallets from './components/FilterWallets';
import { toast } from 'react-toastify';

function WalletModule() {
  const dispatch = useDispatch();
  const { isLoading, WALLET_LIST } = useSelector((state) => state.wallet);
  const [loading, setLoading] = useState(false);
  const [dataPageFetched, setDataPageFetched] = useState(1);

  useEffect(() => {
    dispatch(getWalletAction({ page: 1 }));
  }, []);

  const _filterWallet = async (values) => {
    setLoading(true);
    const response = await dispatch(getWalletAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setLoading(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'fullname',
      label: 'Vendor Fullname',
      align: 'left',
      render: (index, rowData) => rowData?.vendor?.fullname
    },
    {
      id: 'username',
      label: ' Username',
      align: 'left',
      render: (index, rowData) => rowData?.vendor?.username
    },
    {
      id: 'amount',
      label: 'Amount',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData?.amount)
    },
    {
      id: 'phone',
      label: 'Vendor Phone',
      align: 'left',
      render: (index, rowData) => rowData?.vendor?.phone
    }
  ];

  const handleGetMoreData = () => {
    dispatch(getWalletAction({ page: dataPageFetched + 1 }));
    setDataPageFetched((prev) => prev + 1);
  };
  return (
    <Page title="Wallet Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Wallet
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FilterWallets handleFilter={_filterWallet} loading={loading} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={WALLET_LIST}
          isLoading={isLoading}
          filterField="username"
          tableTitle="Wallet"
          getMoreData={handleGetMoreData}
        />
      </Container>
    </Page>
  );
}

export default WalletModule;
